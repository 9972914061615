// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ content }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="section"
        style={{
          background: "url(" + require(`assets/images/BG.png`) + ")",
          backgroundColor: "#000",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Container className="py-4">
          <Row className="align-items-center justify-content-center">
            <Col md={12} xs={12} className="text-center">
              <img
                src={require(`assets/images/logo/Logo.png`)}
                style={{ maxWidth: "400px" }}
                width="100%"
                alt=""
              />

              {content ? (
                content
              ) : (
                <>
                  <h1
                    className="text-700 mt-3"
                    style={{ fontSize: size ? "1.5rem" : "1rem", color: "#000" }}
                  >
                    29th MAY 2024
                    <br />
                    DUBAI, UAE
                  </h1>
                </>
              )}
            </Col>

            <Col md={7} xs={12} className="mt-4" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/sponsor/sponsorship-enquiry"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        BECOME A SPONSOR
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/register"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        REGISTER{size ? " " : <br />}INTEREST
                      </p>
                    </Button>{" "}
                  </Col>
                  {/* <Col lg={3} xs={12} className="text-center">
                    <iframe
                      title="linkedin"
                      style={{ border: "none" }}
                      src="https://www.linkedin.com/pages-extensions/FollowCompany?id=31343765&counter=bottom"
                    ></iframe>
                  </Col> */}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
