import React, { useContext } from "react";
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { groupBy } from "views/content";
import { DataContext } from "DataContainer";
import moment from "moment";

function Agenda() {
  const { agenda } = useContext(DataContext);
  const dayWiseAgenda = groupBy("day")(agenda);
  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card
            style={
              index === data.length - 1
                ? { backgroundColor: "#1c974b" }
                : { backgroundColor: "#1c974b" }
            }
          >
            <CardBody>
              <Container>
                <Row className="py-2">
                  <Col lg={2}>
                    <h2 className="text-600 mt-0 text-white">
                      <b>
                        {moment.utc(d.startAt).format("HH:mm")} -{" "}
                        {moment.utc(d.endAt).format("HH:mm")}{" "}
                      </b>{" "}
                    </h2>
                  </Col>
                  <Col lg={9} className="text-white">
                    <h2 className="text-400 m-0">
                      <b>{d.title}</b>
                    </h2>
                    <p
                      className="text-400 text-white pt-4"
                      id="agendaDescription"
                      style={{ color: "#ffffff !important" }}
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    />
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <h2 className="text-700 text-white">(Gulf Standard Time)</h2>
                  </div>
                </div>

                <Container>
                  <Row>
                    <AgendaCard data={dayWiseAgenda[1] || []} />
                    <Col lg={12} className={"text-center"}>
                      <Button href="/regsiter" className="btn" color="info" size="md">
                        Register Now
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

export default Agenda;
