import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Footer from "components/Footer.js";
import Awards from "components/AwardsPage";
import FAQ from "components/AwardsPageFAQ";
import Register from "components/RegisterAwards";

function SponsorPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="KSA CISO POWERLIST 2020" color="#fff" />
      <Awards />
      <Register />
      <FAQ />
      <Footer />
    </>
  );
}

export default SponsorPage;
