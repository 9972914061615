import React from "react";
import { Container, Row, Col } from "reactstrap";

function ProfilePageHeader({ title, bgColor }) {
  let pageHeader = React.createRef();

  const padding =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? "7rem 0 0rem 0rem"
      : "5rem 0 1rem 0rem";

  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          padding: padding,
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <h1 className={"text-center text-g section-title"}>
            <b>{title}</b>
          </h1>
        ) : (
          ""
        )}
      </div>
      <Container fluid style={{ backgroundColor: "#00b833" }}>
        <Row className="justify-content-center">
          {content.map((c, i) => (
            <Col lg={3} xs={6} key={i} className="py-3 text-center text-white">
              <h2 className="text-700 my-0">
                <i class={c.icon} aria-hidden="true"></i>
                {"  "}
                {c.value}
              </h2>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default ProfilePageHeader;

const content = [
  { title: "Venue", icon: "fa fa-map-marker", value: "HYBRID EVENT" },
  {
    title: "C-Suite leaders",
    icon: "fa fa-users",
    value: "300+ DELEGATES",
  },
  {
    title: "Date",
    icon: "fa fa-calendar",
    value: "26th AUGUST 2023",
  },

  { title: "Countries", icon: "fa fa-globe", value: "UAE, GCC & WORLDWIDE!" },
];
