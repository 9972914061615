import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#1c974b",
        }}
        className="py-2"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            {content.map((c, i) => (
              <Col lg={3} xs={6} key={i} className="text-center pt-2 text-white">
                <h1 className="text-700 d-inline mt-0" style={{ fontSize: size ? "3rem" : "2rem" }}>
                  <CountUp end={c.value} duration={3} />
                  {c.plus && "+"}
                </h1>
                <h1 className="text-400 mt-0" style={{ fontSize: size ? "1.5rem" : "1rem" }}>
                  {c.title}
                </h1>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  { title: "ATTENDEES", value: 150, image: 32, plus: true },
  { title: "SPEAKERS", value: 25, image: 33, plus: true },
  { title: "SPONSORS", value: 20, image: 34, plus: true },
];
