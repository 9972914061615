import React from "react";

import { Container, Row, Col, Card, Modal } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3 text-white"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0 text-center">
                <div>
                  <img
                    src={require(`assets/speakers/${speaker.image}`)}
                    className="rounded-circle"
                    width="100%"
                    alt="speaker"
                  />
                </div>
              </Col>

              <Col xs={12} className="text-center">
                <h3 className="text-700 text-uppercase mt-2 m-0 d-block px-0">{speaker.name}</h3>

                <small className="author m-0">{speaker.title}</small>
                <h4 className="text-700  text-uppercase m-0 d-block px-0">{speaker.company}</h4>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#00ade8",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1 className="text-white text-center section-title">
                <b>2023 SPEAKERS</b>
              </h1>
            </Col>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="ml-auto mr-auto px-1" lg={3} md={6} key={index}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
        {/* <br />
        <br />
        <Col lg={12} className="">
          <h1 className="text-dark text-center section-title">
            <b>ASK OUR SPEAKING PANEL ABOUT REMOTE WORK!</b>
          </h1>
        </Col>
        <iframe
          src="https://app.sli.do/event/pb6uxsbh"
          title="speakers"
          height="100%"
          width="100%"
          style={{ minHeight: "560px" }}
        ></iframe> */}
      </div>
    </>
  );
}

export default SpeakerGrid;

export const speakers = [
  {
    name: "Nada Seder",
    title: "Project Manager",
    company: "Odoo Middle East",
    image: "NADA SEDAR PIC.JPG",
    text: [``],
  },
  { name: "Rishi Sapra", title: "Associate Director", company: "MMJS", image: "Rishi Pic.jpg", text: [``] },
  { name: "Amit Mehta", title: "Associate Partner", company: "UHY JAMES", image: "Amit pic.jpeg", text: [``] },
  { name: "Dinesh Khator", title: "Tax Director", company: "Deloitte UAE", image: "Dinesh Khator pic.jpg", text: [``] },
  {
    name: "Dipesh Chauhan",
    title: "Associate Director",
    company: "ARDENT Advisory and Accounting",
    image: "Dipesh pic.jpg",
    text: [``],
  },
  { name: "Girish Chand", title: "Senior Partner", company: "MCA Gulf", image: "Girish pic.jpg", text: [``] },
  {
    name: "Manoj Pandey",
    title: "COO",
    company: "MBG Corporate Services Abu Dhabi",
    image: "Manoj Pandey PIC.png",
    text: [``],
  },
  {
    name: "Nandaraja Shetty",
    title: "Senior Country Manager",
    company: "Tally Solutions Private Limited",
    image: "speaker.png",
    text: [``],
  },
  {
    name: "Krishnan NV",
    title: "Vice Chairman",
    company: "ICAI Auh chapter",
    image: "CA Krishnan NV.JPG",
    text: [``],
  },
  { name: "Rakesh Nair", title: "Director", company: "Crowe", image: "Rakesh Nair pic.jpeg", text: [``] },
  {
    name: "Avinash Gupta",
    title: "Partner",
    company: "BOT APT Global Tax Consultants",
    image: "Avinash Gupta.jpeg",
    text: [``],
  },
  {
    name: "John George",
    title: "Chairman",
    company: "ICAI Abu Dhabi Chapter",
    image: "John 2.jpg",
    text: [``],
  },
  { name: "Sheetal Soni", title: "Partner", company: "MI Capital Services", image: "SHEETAL PIC.jpg", text: [``] },
  {
    name: "Prateek Tosniwal",
    title: "Partner",
    company: "MI Capital Services",
    image: "PRATEEK PIC.jpg",
    text: [``],
  },
  {
    name: "Anurag Chaturvedi",
    title: "Chief Executive Officer & Managing Partner",
    company: "Andersen",
    image: "ANURAG_1.jpg",
    text: [``],
  },
  {
    name: "Vijaya Mohan",
    title: "Managing Partner",
    company: "Evas International",
    image: "Vijay_Mohan.jpg",
    text: [``],
  },
  {
    name: "Nikhil Joshi",
    title: "Chief Financial Officer",
    company: "Data Capture Systems group",
    image: "Nikhil Profesional Photo.jpg",
    text: [``],
  },
  {
    name: "Mohammed Al Katheeri",
    title: "Partner",
    company: "Premier Brains",
    image: "Mohammed Pic.jpeg",
    text: [``],
  },
  {
    name: "Rohit Dayma",
    title: "General Secretary",
    company: "icai Abu Dhabi chapter",
    image: "Rohit Dayma.jpeg",
    text: [``],
  },
];
