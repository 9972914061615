import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "components/SpeakerCardAll";
import { speakers } from "./content";
import Footer from "components/Footer.js";
import Testimonials from "components/Testimonials.js";

function SponsorPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="PAST SPEAKERS" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <SpeakerCardAll speakers={speakers} />
      </div>
      <Testimonials />

      <Footer />
    </>
  );
}

export default SponsorPage;
