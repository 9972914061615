/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
// import "assets/scss/paper-kit.scss";
import "assets/css/paper-kit.min.css";
import "assets/demo/demo.css";

import Home from "views/home.js";
import CorporateTax from "views/corporateTax";

import RegisterPage from "views/Register.js";
import RegisterCorporate from "views/RegisterCorporate.js";
import RequestBrochureCorporate from "views/RequestBrochureCorporate.js";

import Sponsor from "views/Sponsor";
import SpeakerPage from "views/SpeakerPage";
import PastSpeakerPage from "views/PastSpeakers";

import SponsorSingle from "views/SponsorSingle";
import CorporateSponsorSingle from "views/SponsorSingleCoporate";
import Privacy from "views/Privacy";
import AwardsPage from "views/AwardsPage";

// import SponsorPage from "views/SponsorPage";
import Overview from "./views/Overview";
import AgendaPage from "./views/Agenda";
import ThankYou from "./views/ThankYou";

import ThankYouRegisterCorporate from "./views/ThankYouRegisterCorporate.js";
import ThankYouRegister from "./views/ThankYouRegister";
import CorporateContact from "./views/CorporateContact.js";

import Attend from "views/Attend";
import DataProvider from "./DataContainer";
import CorporateTaxDecode from "views/CorporateTaxDecode";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route
          path="/corporate-tax-decoded"
          exact
          render={(props) => <CorporateTax {...props} />}
        />
        <Route
          path="/corporate-tax-decoded/contact-us"
          exact
          render={(props) => <CorporateContact {...props} />}
        />
        <Route path="/overview" exact render={(props) => <Overview {...props} />} />
        <Route path="/overview/:type" exact render={(props) => <Overview {...props} />} />
        <Route path="/sponsor" exact render={(props) => <Sponsor {...props} />} />
        <Route path="/sponsor/:type" exact render={(props) => <Sponsor {...props} />} />

        <Route path="/speakers" exact render={(props) => <SpeakerPage {...props} />} />
        <Route path="/past-speakers" exact render={(props) => <PastSpeakerPage {...props} />} />
        <Route path="/sponsors/:sponsor" exact render={(props) => <SponsorSingle {...props} />} />
        <Route
          path="/corporatesponsors/:sponsor"
          exact
          render={(props) => <CorporateSponsorSingle {...props} />}
        />
        <Route path="/register" exact render={(props) => <RegisterPage {...props} />} />
        <Route
          path="/register-corporate-tax-decoded"
          exact
          render={(props) => <RegisterCorporate {...props} />}
        />
        <Route
          path="/brochure-corporate-tax-decoded"
          exact
          render={(props) => <RequestBrochureCorporate {...props} />}
        />

        <Route path="/agenda" exact render={(props) => <AgendaPage {...props} />} />
        <Route path="/attend" exact render={(props) => <Attend {...props} />} />
        <Route
          path="/thank-you-yes"
          exact
          render={(props) => <ThankYou {...props} answer="YES" />}
        />
        <Route path="/thank-you-no" exact render={(props) => <ThankYou {...props} answer="NO" />} />
        <Route
          path="/thank-you/:id"
          exact
          render={(props) => <ThankYouRegister {...props} answer="" />}
        />
        <Route
          path="/corporate-thank-you/:id"
          exact
          render={(props) => <ThankYouRegisterCorporate {...props} answer="" />}
        />

        <Route path="/privacy-policy" exact render={(props) => <Privacy {...props} />} />
        <Route path="/powerlist" exact render={(props) => <AwardsPage {...props} />} />

        <Route path="/corporate-tax-decode" exact render={(props) => <CorporateTaxDecode {...props} />} />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
