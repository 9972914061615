import React from "react";
import CountUp from "react-countup";
import { Container, Row, Col } from "reactstrap";

function ProfilePageHeader({ title, bgColor }) {
  let pageHeader = React.createRef();

  const padding =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024
      ? "7rem 0 0rem 0rem"
      : "5rem 0 1rem 0rem";
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          padding: padding,
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <h1 className={"text-center text-g section-title"}>
            <b>{title}</b>
          </h1>
        ) : (
          ""
        )}
      </div>
      <Container fluid style={{ backgroundColor: "#1c974b" }}>
        <Row className="justify-content-center">
          {content.map((c, i) => (
            <Col lg={3} xs={3} key={i} className="text-center py-3 text-white">
              <h1 className="text-700 d-inline mt-0" style={{ fontSize: size ? "2rem" : "1rem" }}>
                <CountUp end={c.value} duration={3} />
                {c.plus && "+"} {c.title}
              </h1>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default ProfilePageHeader;

const content = [
  { title: "ATTENDEES", value: 150, image: 32, plus: true },
  { title: "SPEAKERS", value: 25, image: 33, plus: true },
  { title: "SPONSORS", value: 20, image: 34, plus: true },
];
