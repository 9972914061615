import React from "react";

import { Container, Row, Col } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhoAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
        }}
        className="section section-with-space pb-3"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className=" mb-4 text-400 text-center text-main" style={{ fontSize: "3rem" }}>
                <b>WHY ATTEND</b>
              </h1>
              <br />
            </Col>
            <Col lg={12}>
              <Container fluid>
                <Row className="justify-content-center pt-3">
                  {content2.map((data, index) => (
                    <Col lg={6} key={index} className="my-4">
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                        <div
                          className="p-2 text-center"
                          style={{
                            background:
                              "linear-gradient(270deg, rgba(28,151,75,1) 0%, rgba(26,130,172,1) 97%)",
                            borderRadius: "10px",
                            minHeight: "60px",
                            border: "1px solid black",
                          }}
                        >
                          <h2 className="text-700 text-white text-uppercase m-0">{data.title}</h2>
                        </div>
                        <h3 className="text-400 text-white pt-3">{data.desc}</h3>
                        {/* <h3 className="text-700 pt-3">
                            <span className="text-success">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>{" "}
                            {data.title}
                          </h3>
                          <h3 className="text-4 pt-3">{data.desc}</h3> */}
                      </ScrollAnimation>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#000",
          backgroundImage:
            "url(" +
            require("assets/images/corporate-businessman-giving-presentation-large-audience.jpg") +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="pb-5"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} className="text-left pt-2">
              <h1 className="  text-700 pt-5 text-white">Primary Industries</h1>
              {industries.map((c, i) => (
                <h3 className="text-400 mt-0 text-capitalize text-white" key={i}>
                  <i className="fa fa-caret-right text-g" aria-hidden="true"></i>
                  {c}
                </h3>
              ))}
            </Col>

            <Col lg={5} className="text-left pt-2">
              <h1 className="text-700 pt-5 text-white">Attendees’ Profiles</h1>
              {categories.map((c, i) => (
                <h3 className="text-400 mt-0 text-capitalize text-white" key={i}>
                  <i className="fa fa-caret-right text-g" aria-hidden="true"></i>
                  {c}
                </h3>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content2 = [
  {
    title: "To gain strategic insights",
    desc: "Gain unparalleled insights into the future intricacies of corporate finance and accounting with a focus on the evolving role of CFOs, agility, resilience, compliance to regulations, and the impact of technology breakthroughs.",
  },
  {
    title: "In-depth focus areas",
    desc: "Deepen your understanding of critical areas like CFO role expansion, accounting agility, tax compliance, and technology adoption, ensuring you are well-equipped to navigate the evolving finance landscape.",
  },
  {
    title: "In-depth analysis of industry trends",
    desc: "Stay ahead of the curve by delving into top industry trends in 2024, including increased adoption of AI, blockchain in accounting, cloud computing, and sustainable accounting.",
  },
  {
    title: "Dissect the top industry challenges and discover innovative solutions",
    desc: "Gain insights from industry experts as they address key challenges like payroll management, financial forecast accuracy, and employee retention through expert discussions and shared solutions.",
  },
  {
    title: "Quality networking opportunities",
    desc: "Connect with industry leaders, CFOs, financial analysts, and finance experts from diverse sectors, fostering collaboration and knowledge exchange amongst the industry experts.",
  },
  {
    title: "Discover emerging technologies",
    desc: "Stay enlightened about cutting-edge technologies shaping the future, such as AI, machine learning, blockchain, and cloud computing, offering unique insights and applications in your role.",
  },
];
const categories = [
  " Government Sector Experts",
  " Chief Financial Officers (CFOs)",
  " Chief Technology Officers",
  "(CTOs) Chief Information Officers",
  "(CIOs) Finance and Tax Regulators",
  " Compliance Directors",
  " Directors of Finance",
  " Heads of Accounting",
  " Financial Analysts",
  " Credit Managers",
  " Directors of internal Audit",
  " Tax Directors",
  " Financial Planning and Analysis (FP&A) Directors",
  " Risk Directors",
  " Corporate Finance Directors",
  " Directors of Revenue Management",
  " Financial Controllers",
];

const industries = [
  "BFSI ",
  "IT & Telecommunication ",
  "Automotive ",
  "Retail & Consumer Goods ",
  "Oil & Gas ",
  "Manufacturing ",
  "Healthcare ",
  "Government & Public Sector ",
  "Construction & Real Estate ",
  " Logistics and Supply Chain",
];

export default WhoAttend;
