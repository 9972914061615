import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#fbfbfb",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <img
                src={require(`assets/images/attend.jpg`)}
                width="100%"
                alt=""
                // style={{ border: "15px solid #34368b" }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="text-dark">
              <h3 className="text-400">
                <br />
                <br />
                The effects of the current global situation are likely to change
                the way world does business. Businesses are being forced to
                re-think and re-evaluate.
                <br />
                <br /> Resilience is the new buzzword. Each and every department
                has had to adapt to succeed. The finance and accounting
                department has seen technology play a bigger and better role in
                in this pandemic, helping them execute their tasks in a more
                efficient manner with a better turnaround time and help
                organization’s make sense of their historical data in order to
                take informed strategic business and financial decisions. <br />
                <br />
                As the leading regional newspaper, Khaleej Times has been at the
                forefront; reporting news, initiating new mediums and
                re-inventing Events and Conferences!
                <br />
                <br /> Presenting the{" "}
                <b> New-Age Finance and Accounting Summit</b> to be taking place
                on <b>14th MARCH 2023</b>
                .The In Person KT NAFA 2023 will bring over 1000+ finance leaders
                and professionals from across GCC countries together to discuss
                on the existing professional landscape, deliberate on the role
                of technology within their domain and develop strategies for
                upcoming opportunities and challenges.
                <br />
                <br />
                With deep rooted research of the Industry the In Person Summit will
                focus on Business Transformation through advanced technologies,
                Cultural Transformation after Pandemic, Impact of AI, Machine
                Learning, RPA, Predictive Analytics, Blockchain, Cloud
                infrastructure & much more!
                <br />
                <br /> The agenda is structured to encourage active meaningful
                conversations all day enhanced networking and interaction
                opportunities, including Masterclass & Workshops by Industry
                associations & tech-leaders.
                <br />
                <br /> KT NAFA 2023’s aim is empower to take their organization
                through to the next phase of growth.
              </h3>
            </Col>

            <Col md={12} className="mx-auto mt-5 align-self-center text-center">
              <Button
                href="/sponsor/download-brochure"
                className="btn"
                color="primary"
                size="md"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  REQUEST AGENDA
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default TextGrid;
