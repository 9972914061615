import React from "react";
import Navbar from "components/NavbarCorporate.js";
import ProfilePageHeader from "components/Headers/CorporateProfilePageHeader.js";
// import About from "components/About";
// import Organizer from "components/Organizer";
import ContactUs from "components/ContactUs";

import Footer from "components/Footer.js";
// import { Redirect } from "react-router";
import Sponsor from "components/CorporateSponsor.js";
import Testimonials from "components/Testimonials.js";
import { sponsors, sponsorsPage, associations } from "./content";

function Overview() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="CONTACT US" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <ContactUs />
      </div>
      <Testimonials />
      <Sponsor sponsors={sponsors} sponsorsPage={sponsorsPage} associations={associations} />
      <Footer />
    </>
  );
}

export default Overview;
