import { Col, Container, Row } from "reactstrap";
import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

function WhySponsor() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
        }}
        className="section section-with-space  py-3"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className=" mb-4 text-400 text-center text-main" style={{ fontSize: "3rem" }}>
                <b>WHY SPONSOR</b>
              </h1>
              <br />
            </Col>
            {content.map((data, index) => (
              <Col lg={6} xs={12} key={index} className="py-5 text-center">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                  <div
                    className="p-2"
                    style={{
                      background:
                        "linear-gradient(270deg, rgba(28,151,75,1) 0%, rgba(26,130,172,1) 97%)",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <h2 className="text-700 text-white text-uppercase m-0">
                      <img
                        src={require(`assets/images/icons/${data.image}`)}
                        style={{ maxWidth: "50px" }}
                        alt="dataimage"
                      />{" "}
                      {data.title}
                    </h2>
                  </div>
                  <h3 className="text-400 pt-2 text-white">{data.description}</h3>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#00ade8",
        }}
        className="py-2"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className=" text-center text-700 p-2 text-white">THE POWER OF ONLINE PRESENCE</h1>
            </Col>

            {stats.map((c, i) => (
              <Col lg={3} xs={6} key={i} className="text-center pt-2 text-white">
                <h1 className="text-700 d-inline mt-0" style={{ fontSize: size ? "3rem" : "2rem" }}>
                  {c.value}
                  {c.plus && "+"}
                </h1>
                <h1 className="text-400 mt-0" style={{ fontSize: size ? "1.5rem" : "1rem" }}>
                  {c.title}
                </h1>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#000",
          backgroundImage:
            "url(" +
            require("assets/images/partnership-business.jpg") +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="pb-5"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-start">
            <Col lg={12}>
              <h1 className=" text-left text-700 pt-5 text-white">Who Should Sponsor</h1>
            </Col>
            <Col lg={12}>
              <h3 className="text-400 pt-4 text-white">
                New Age Finance And Accounting Summit will be the Middle East's one stop shop to
                understand the technological innovation, digital disruption & magnitude of services
                will change the way business is done.
              </h3>
            </Col>
            {categories.map((c, i) => (
              <Col lg={6} key={i} className="text-left pt-2">
                <h3 className="text-400 mt-0 text-capitalize text-white">
                  <i className="fa fa-caret-right text-g" aria-hidden="true"></i>
                  {c}
                </h3>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    title: "Exclusive exposure to industry leaders",
    image: "16.png",
    description: `Position your brand as a key player by sponsoring an event that
    attracts top-tier industry professionals, including CFOs, financial analysts, and heads of accounting,
    offering exclusive exposure and networking opportunities.`,
  },
  {
    title: "Direct targeted market reach",
    image: "15.png",
    description: `Gain access to a niche audience in the finance sector, with a focus on BFSI,
    IT & Telecommunication, Automotive, and other key industries, ensuring that your brand is showcased to
    a relevant and engaged market.`,
  },
  {
    title: "Networking opportunities with decision-makers",
    image: "14.png",
    description: `Leverage valuable connections with decision-makers,
    including regulators, CFOs, CTOs, and heads of finance, providing a platform to showcase your products
    or services and establish long-term business relationships.`,
  },
  {
    title: "Tailored sponsorship categories",
    image: "13.png",
    description: `Choose from a variety of sponsorship categories tailored to suit your
    business objectives, whether you're a FinTech company, accounting software provider, financial service
    firm, or a consulting firm, ensuring maximum impact and alignment with your target market’s needs.`,
  },
  {
    title: "Access to market insights",
    image: "13.png",
    description: `Leverage the event to gain firsthand insights into market trends, industry
    challenges, and competitor landscapes, empowering your organization with valuable knowledge to
    refine strategies and stay ahead in the rapidly evolving finance and accounting sector.
    `,
  },
];
const stats = [
  { title: "Million Monthly Page Views", value: "30", plus: false },
  { title: "Newsletter Subscribers", value: "250000", plus: true },
  { title: "Million Social Media Reach", value: "4", plus: true },
];

const categories = [
  "Financial Technology (FinTech) Companies",
  "Accounting Software Providers",
  "Accounting Firms",
  "Financial Services Firms",
  "Tax Advisory Services",
  "Consulting Firms",
  "Audit and Assurance Services",
  "Banks and Financial Institutions",
  "Investment and Asset Management Companies",
  "Corporate Training and Education Providers",
  "Law Firms specializing in Finance and Tax",
  "Enterprise Resource Planning (ERP) Providers",
  "Payment Processors",
  "Risk Management Solutions Providers",
  "Credit Reporting Agencies",
  "Financial Data and Analytics Providers",
  "ID Verification Software Providers",
  "Fraud Detection Software Providers",
  "Insurance Companies",
];

export default WhySponsor;
