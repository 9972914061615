export const newSpeakers = [
  {
    name: "John Sanei",
    title: "",
    company: "Future Strategist",
    image: "John Sanei pic.JPG",
    text: [
      `John Sanei is a highly acclaimed future
    strategist and transformation specialist
    whose mission is to help organisations
    get excited for the future face of work,
    organisational restructuring and the
    future of education.`,
    ],
  },
  {
    name: "Martin Bradley",
    title: "Chief Financial Officer",
    company: "Dulsco",
    image: "Martin Bradley pic.jpg",
    text: [
      `Martin Bradley has a wealth of experience in financial strategic planning, spanning more than two decades since 1994. He has worked with significant manufacturing and government organisations in Scotland, showcasing his expertise in the field. His international experience encompasses prominent roles in risk mitigation operating from Singapore, before moving to the United Arab Emirates in 2009.
<br/>
<br/>
    In the UAE, he has successfully planned and executed transformation strategies across various functions, such as finance, IT, HR, and procurement, leading to the awarding of multibillion dollar tenders through strong corporate governance and financial innovation.   Martin Bradley is committed to driving change and innovation, and is spearheading ‘Chrysalis,’ a digital transformation initiative that will enhance business performance and customer satisfaction, by implementing a fully integrated cloud-based ERP system.
    <br/><br/>
    Since joining Dulsco in 2019 as the CFO, he has been entrusted with leading the business transformation of the 88-year-old Emirati organisation.`,
    ],
  },
  {
    name: "Pavithra Balaji",
    title: "Tax Director - ASIA & META",
    company: "GE",
    image: "Picture Pavithra.png",
    text: [
      `Pavithra Balaji is the Tax Director for Asia, Middle East, Turkey and Africa regions for  General Electric- Corporate. In this role, Pavithra is responsible for the tax compliance function for GE businesses  operating through Corporate legal entities across 35 plus countries in the 2 regions. She oversees the tax planning, tax operations, controllership and compliance efforts for the GE Group entities thus playing a key role in implementing GE’s overall corporate tax strategy across Asia and META. 
<br/><br/>
    Pavithra joined GE in 2010 and has held diverse functional and leadership roles within the GE group in financial systems, taxation and controllership. She served as ERP Tax Leader for GE Power, providing tax and accounting expertise in implementing an Oracle based ERP system across Middle East and Africa. In 2015, Pavithra successfully established GE’s first integrated Regional Statutory & Tax Center of Excellence,  covering wing-2-wing regulatory compliance including statutory financial accounting and audits under IFRS / local country frameworks and tax operational processes. 
    <br/><br/>
    Prior to joining GE, Pavithra worked in finance, audit and taxation roles at several companies across both industry and profession in GCC and India.   She came to GE from Bank Sarasin Alpen in Oman, where she was Compliance Director. She also spent several years with KPMG, providing audit and taxation services to multinational companies operating in the Sultanate of Oman.
    <br/><br/>
    Pavithra is an Associate Member of the Institute of Chartered Accountants of India as well as the Institute of Costs and Works Accountants of India.  As a sought after subject matter expert in the region she is a regular speaker at Finance and Tax conferences in the UAE including events arranged by IQPC, Informa Connect, Accounting & Finance Show and the Dubai Chapter of the Institute of the Chartered Accountants of India (ICAI). She is an Advisory Board Member of the Center of Excellence for Applied Research and Training (CERT- www.cert.ae) VAT Academy.  
    <br/><br/>
    Pavithra is married with 2 children and is based in Dubai, United Arab Emirates.
    
    `,
    ],
  },
  {
    name: "Ramesh Subramanian",
    title: "Chief Financial Officer",
    company: "Ras Al Khaimah Economic Zone",
    image: "Ramesh Pic.jpg",
    text: [
      `With a 25-year prior experience in financial head and general manager roles at global MNCs, conglomerates and governments in the Middle East and India, Ramesh has been leading the financial operations at RAKEZ since 2019.
<br/><br/>
    His understanding of multi-industries and emerging markets and expertise in shaping business policies and solving growth challenges helped him drive innovative operational strategies for the organizations he has served.
    <br/><br/>
    He is a fellow member of Institute of Chartered Accountants India  & The  Association of International Accountants, United Kingdom and holds a  post-graduation in  Management
    `,
    ],
  },
  {
    name: "Ramkumar Balasubramaniam",
    title: "Chief Financial Officer",
    company: "Barclays",
    image: "Ram Barclays Pic.jpg",
    text: [
      `Ram is an International Banking CFO with a wealth of Finance experience working in Retail, Corporate and Investment Banks. He has led several Finance transformation projects and has leveraged the unique position of the CFO at the intersection of finance, technology and strategy to drive digitalization and inspiring a data-driven decision making culture. He is also engaged with the wider Finance community, often speaking at events on the challenges facing the function and how to be ready for the future or making the function future proof`,
    ],
  },
  {
    name: "Fazeela Gopalani",
    title: "Head of Middle East",
    company: "ACCA",
    image: "Fazeela Gopalani pic.jpg",
    text: [
      `With accounting in her blood thanks to her father who was an ACCA member and ran a successful accounting practice in the UK, providing professional financial services to a variety of clients who were private individuals, small businesses and large enterprises it’s no surprise Fazeela was convinced of the power of the ACCA qualification and truly believes that working for ACCA and inspiring others to follow this career path is the utmost tribute to her father.
    <br/><br/>As Head of the Association of Chartered Certified Accountants (ACCA) in the Middle East, Fazeela is responsible for leading the operations in 11 countries and representing more than 20,000 students, affiliates and members across the region, who work in all sectors and all levels of business across the Middle East. One of her fundamental roles is as a thought leader and a conversation starter for all things “accounting and finance profession”, in the Middle East, with the purpose of growing the understanding of the value that professionally qualified accountants bring to businesses and economies across the region.
    `,
    ],
  },

  {
    name: "Armin Moradi",
    title: "CEO and Co-founder",
    company: "Qashio",
    image: "Armin Moradi Profile Photo.jpg",
    text: [
      `Armin Moradi, CEO and Co-founder of Qashio is a serial entrepreneur with over a decade of financial software experience. With the expertise he gained during his tenure at Oracle, Armin founded Lytecone, a software implementation company focused on improving business performance through the use of innovative technological solutions. After hes exit, Armin joined Carriage, a Delivery Hero Company, in 2019, where he was appointed as Managing Director for UAE and KSA. Post merger between Carriage and Talabat, Armin took on the role of Regional Commercial Director for the newly formed Food Services department. Armin later joined Royal Avenue investments, an entity of the Oman Sovereign wealth fund as their CFO.
<br/>
<br/>
    Armin has always been a proponent of software as a solution for improving business performance and wanted to develop a solution for one of the biggest challenges he faced day to day, 
    <br/><br/>
    Since founding Qashio in 2021, the company has made significant progress and are continuing to build further on Qashio’s features and functionalities. The appetite for a comprehensive and reliable Spend management solution in the region has been significant and Qashio are proud to be able to offer a solution that can make a real impact on business growth.
    
    
    `,
    ],
  },
  {
    name: "Amit Agarwal",
    title: "Chief Financial Officer",
    company: "Trukker",
    image: "Amits-Picture.jpg",
    text: [
      `Amit Agarwal is the CFO of TruKKer, region’s largest digital freight platform. Amit has played an instrumental role in TruKKer’s success providing strategic leadership and is responsible for the fundraising, investor relations and financial management. He has successfully raised approx. $200 million funding in equity and debt getting onboard large institutional investors including Mubadala, Investcorp, ADQ, STV, amongst others.
    <br/><br/>Amit has extensive experience in Mergers & Acquisitions, IPOs, Debt Markets, and Venture Capital having worked at Goldman Sachs for over eleven years, working across Dubai, India, London and New York
    <br/><br/>Amit received a CA degree from the Institute of Chartered Accountants of India. Outside of work, Amit enjoys travelling and spending time with family and friends. He lives with his wife and two children in Dubai.`,
    ],
  },
  {
    name: "Padmanabha Acharya",
    title: "President",
    company: "IBPG UAE / Deloitte",
    image: "Paddy Pic.jpg",
    companyImage: "unilever.png",
    text: [""],
  },
  {
    name: "Shiraz Khan",
    title: "Partner",
    company: "Al Tamimi & Company",
    image: "Shiraz Khan Pic.jpg",
    text: [
      `
    Shiraz is a UK qualified lawyer with over 21 years of international tax experience. Prior to joining Al
Tamimi, he was the global head of tax at a leading multinational where he was responsible for the tax
affairs in over 100 countries. He has also worked for a top international law firm in the UK and big 4 firms
in both the UK and the Middle East.<br/><br/>
Shiraz specialises in advising on international tax structuring and tax aspects of cross border M&A,
private equity, structured finance, Islamic finance and real estate transactions. He has in-depth
knowledge and expertise across all taxes including corporate tax, withholding taxes and VAT in a wide
range of industry sectors. He has particular expertise in advising multinational corporations on structuring
their investments and operations into the region and financial institutions, sovereign wealth funds, real
estate investment companies, family offices and private equity groups based in the region on their
international investments.<br/><br/>
Shiraz regularly speaks at conferences and seminars on international tax issues and has been involved
in advising governments on the drafting of tax laws.
    `,
    ],
  },
  {
    name: "Carlos Sagra",
    title: "Head of Risk Management",
    company: "Invest AD - Abu Dhabi Investment Company",
    image: "Carlos Pic.jpg",
    text: [],
  },
  {
    name: "Sanjiv Jain",
    title: "Chief Financial Officer",
    company: "G42",
    image: "Sanjiv[2].jpg",
    text: [``],
  },

  {
    name: "Yacin El Ouadih",
    title: "Director Risk Underwriting - (Trade Credit Insurance)",
    company: "Etihad Credit Insurance",
    image: "Yacin Pic.jpg",
    text: [],
  },
  {
    name: "Anurag Chaturvedi",
    title: "Chief Executive Officer",
    company: "Andersen UAE",
    image: "ANURAG-COVER.jpg",
    text: [
      `As CEO and Managing Partner of Andersen in the
    United Arab of Emirates, Anurag Chaturvedi carries
    20 years of experience in tax and transaction
    advisories.<br/><br/>
    He holds a distinguished career serving clients on
    VAT, Excise, ESR, AML, and direct tax matters.
    Chaturvedi has clients across the Middle East and
    Asia, both in the public and private sectors.
    <br/><br/>
    As a recognized tax professional, he continues to
    share his experience on tax and the economy with
    clients and industry.<br/><br/>
    He has conducted numerous seminars and written
    blogs to address critical tax matters and has
    contributed to formulating several decisions to
    improve tax development in the region.<br/><br/>
    He currently is also the Chairman of the Institute of
    Chartered Accountants of India, Dubai Chapter.`,
    ],
  },
  {
    name: "Nauman Mian",
    title: `Chief Financial Officer`,
    company: `Bayt.com`,
    image: "nauman.jpg",
    companyImage: "unilever.png",
    text: [
      `Nauman is an Associate member of Chartered Accountants England and Wales, a
    Fellow member of Chartered Certified Accountants and a Certified Internal
    Auditor. He is serving as the Chief Financial Officer at Bayt.com Inc., the Middle
    East's # 1 job site. He started off his career with PricewaterhouseCoopers and went
    on to work for FTSE listed No.1 Health & Fitness provider in U.K.<br/>
    Nauman has demonstrated leadership skills throughout his academic and
    professional career. He holds the prestigious accolade of being the first member
    to be elected at the ACCA Global Council from UAE. Nauman is also a member of
    the Global Tax Forum and Accountants in Businesses Forum at ACCA. He is a
    founding member of UAE Members Advisory Committee for ACCA and also held
    its honorary Chairman position. Nauman has been nominated for multiple awards,
    such as the “Young Accountant of the Year”, and “Chartered Accountant of the
    Year” at the Finance & Accountancy Middle East Awards.`,
    ],
  },

  {
    name: "Karuna Luthar",
    title: "Regional Head - Middle East",
    company: "Social Audit Network",
    image: "Karuna Pic.jpg",
    text: [],
  },
  {
    name: "Kanishka Gupta",
    title: "Group Chief Financial Officer",
    company: "Aujan Group Holding",
    image: "Kanishka.jpg",
    text: [
      `Kanishka joined Aujan Group Holding in 2012 as Group Finance Director. In 2015, he was appointed Group Chief Financial Officer for Aujan Group Holding. Prior to that, Kanishka held the position of Chief Financial Officer at Seaways International, a marine offshore company, and Finance Director at Tatweer Dubai, a Dubai Holding subsidiary. With over 20 years’ experience in family-owned businesses, multinationals and semi-government organizations, Kanishka has held leadership positions across several industries including real estate, FMCG, leisure & entertainment, hospitality, healthcare, marine offshore and trading.
<br/>
<br/>
    As Group CFO, Kanishka is responsible for the financial and risk management of the Aujan businesses, development of Group financial strategy, and management of Group governance framework, control systems and policies designed to preserve company assets and report accurate financial results. He is a key member of the M&A team and his deal dossier includes transactions with Coca Cola, Minor Group Holding and Ball. Active planning and management of tax and compliance risks have resulted in significant savings to the Group. He has spearheaded many financing and debt restructuring programs for the Group making available vital resources to support the strategic growth and continued success of the Group.
    <br/><br/>
    Kanishka is a Chartered Accountant from the Institute of Chartered Accountants of India and holds an MBA from Asian Institute of Management, Philippines which included a merit-based program with Tuck School of Business at Dartmouth University. He has been residing in Dubai with his wife, Manisha, and two children since last 15 years.
    `,
    ],
  },

  {
    name: "Dunja Poetschke",
    title: "Chief Financial Officer",
    company: "Invygo",
    image: "Dunja Poetschke pic.jpg",
    text: [
      `Dunja Poetschke is a seasoned finance professional with over 20 years of global experience in various industries, including SaaS, internet, media, construction, retail, and wholesale. She has successfully led companies through business scaling and transformation, capital management, and raising. With a strong background in all finance disciplines, Dunja has the unique ability to take on strategic and traditional finance roles. She currently serves as the CFO at invygo and previously held leadership positions at Fresha, PropertyFinder and iProperty Group. Dunja holds a Bachelor's degree in Business Administration and is a Certified Management Accountant (CMA).    `,
    ],
  },

  {
    name: "Shady Remeily",
    title: "Group Chief Strategy Officer",
    company: "Nasser Bin Abdullatif Alserkal",
    text: [],
    image: "speaker.png",
  },

  {
    name: "S Venkatesh",
    title: "Managing Partner",
    company: "MCA Gulf",
    image: "S-Venkatesh.jpg",
    text: [],
  },
  {
    name: "Prateem Sengupta",
    title: "Associate Director",
    company: "Direct Tax",
    image: "PRATEEM SENGUPTA.jpg",
    text: [
      `As an Associate Director, Prateem leads the Direct Tax practice at Andersen in the United Arab
    Emirates serving a diverse client portfolio in the region with the help of experienced, agile and
    dedicated tax professionals.<br/><br/>
    Prateem has close to 11 years of experience in providing solutions in all facets of corporate and
    international taxation matters to a diverse client portfolio of large multinationals based out of
    India, USA, Japan, Spain, UAE and Australia. He previously held a senior management position
    at a Big 4 firm in their corporate and international tax practice.<br/><br/>
    Prateem has extensive experience in leading Direct Tax assignments involving tax compliances,
    advisory, structuring, representation before tax / government authorities and tax litigation /
    dispute assignments.<br/><br/>
    His key expertise is in impact assessments of new legislation, mergers and acquisitions, tax
    deductibility of various expenses, nuances around tax losses, taxability of cross charges
    amongst related entities, Permanent Establishment and Royalty / FTS issues, inbound
    structuring, cash repatriation strategies and withholding tax issues.<br/><br/>
    Prateem has also presented and contributed on international and corporate tax matters and
    updates on external forums. He is a Chartered Accountant and a member of the Institute of
    Chartered Accountants of India. Prateem also holds a Bachelor’s degree in Commerce from the
    Calcutta University.`,
    ],
  },
  { name: "Rania Ali", company: "Master of Ceremonies", title: "", text: [], image: "speaker.png" },
  {
    name: "Vikas Panchal",
    title: "General Manager - Middle East",
    company: "Tally Solutions",
    image: "Vikas R Panchal pic.jpg",
    text: [
      `Vikas is the General Manager - Middle East region for Tally Solutions Pvt. Ltd. He completed his
    MBA and did a Management Program from Indian Institute of Management Ahmedabad. He
    holds 20+ years of comprehensive experience in channel development and direct sales in IT
    Industry.<br/><br/>
    Prior to working with Tally Solutions, Vikas held key positions at Sify Limited (Leading
    Broadband Company) and SSI Limited. His experience and various levels of responsibilities hold
    testimony to his versatile personality. Vikas has been associated with Tally for almost 10 years
    and deeply understands the company’s vision and goals.<br/><br/>
    Currently as the Business Head for Middle East, he is largely responsible for building and leading
    MENA Strategy to achieve Tally’s dominance in the region. He prepares the company’s annual
    strategy and ensures its effective execution. Building a stronger distribution network, working
    in sync with the country leads to achieve a wider dominance in countries of operations through
    channel expansion and development, are some of the core areas he oversees. He is closely
    working with influencers (CA’s and CPA’s) to monitor industry developments and influence
    those developments to support overall goals for all.<br/><br/>
    While he is not working, Vikas loves to travel and watch movies.`,
    ],
  },

  {
    name: "Rajiv Hira",
    title: "Managing Partner",
    company: "RHmc Management Consultants",
    text: [],
    image: "Rajiv Hira Profile Pic.jpeg",
  },

  {
    name: "Ramesh Jha",
    title: "Head Treasury and Finance",
    company: "RAK Ceramics",
    text: [],
    image: "Ramesh Pic.jpeg",
  },

  {
    name: "Roopali Jawa",
    title: "GCC Tax Manager",
    company: "Apparel Group",
    text: [],
    image: "Rooplai pic.jpeg",
  },
];

export const speakers = [
  {
    name: "Ramesh Subramanian",
    title: `Chief Financial Officer`,
    company: `Ras Al Khaimah Economic Zone`,
    image: "Ramesh Pic.jpg",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "Dawesh Bishnoi",
    title: `Chief Financial Officer`,
    company: `Liwa Stores`,
    image: "diwesh.png",
    companyImage: "unilever.png",
    text: [
      `With over two decades of experience in finance, investments,
technology and commercials, Dawesh Bishnoi has worked in
global companies spanning Retail, Investments, Consulting and
Engineering operating across GCC, Asia Pacific, South Asia and
Levant.

Dawesh was appointed as the CFO of Liwa Trading Enterprises
in 2020 to support the growth and transformation of the
business. During the Covid years, Dawesh supported the
transition of business from primarily retail stores towards
blended digital Ecommerce. The business expanded profitably
when many other retailers were consolidating their presence.
The focus remained on business growth, our partnerships,
supporting our employees and pleasing our customers`,
    ],
  },
  {
    name: "Narendran Thillaisthanam",
    title: "Chief Technology Officer",
    company: "Vuram",
    image: "Naren Pic -2.jpg",
    text: [
      `
    Narendran Thillaisthanam (Naren) is the CTO of Vuram, a global hyperautomation services
company that specializes in low-code enterprise automation.<br/><br/>
He is a pioneer and a thought leader in the space of Hyperautomation and Emerging
Technologies. He brings more than two decades of industry experience and is based out of
Bangalore, India.<br/><br/>
At Vuram, Naren and his team of consultants provide solutions using RPA, Low Code, BPM,
Doc.AI and Machine/Deep Learning platforms.
    `,
    ],
  },
  {
    name: "Andreas Simon",
    title: `Regional Director MEA`,
    company: `Jedox`,
    image: "Andreas pic.jpg",
    companyImage: "unilever.png",
    text: [
      `As Regional Director MEA for Jedox, I am responsible for growing our footprint in Middle East and Africa
    and seeking new partnerships in the region to add to our expanding global network of partners. I have
    more than two decades of business and technical experience from which 15 years are in the Business
    Intelligence space for companies with in-memory OLAP systems.
    Prior to joining Jedox, I was Senior Director Development for an OLAP system and COO at a BI company
    focused on Anti Money Laundering tools for banks and insurances based on a BI platform.`,
    ],
  },
  {
    name: "Armin Moradi",
    title: `CEO & Co-founder`,
    company: `Qashio`,
    image: "Armin Moradi Photo.jpg",
    companyImage: "unilever.png",
    text: [
      `
    Armin Moradi, CEO and Co-founder of Qashio is a serial entrepreneur with over a decade of
financial software experience. With the expertise he gained during his tenure at Oracle,
Armin founded Lytecone, a software implementation company focused on improving
business performance through the use of innovative technological solutions. After hes exit,
Armin joined Carriage, a Delivery Hero Company, in 2019, where he was appointed as
Managing Director for UAE and KSA. Post merger between Carriage and Talabat, Armin
took on the role of Regional Commercial Director for the newly formed Food Services
department. Armin later joined Royal Avenue investments, an entity of the Oman Sovereign
wealth fund as their CFO.<br/><br/>
Armin has always been a proponent of software as a solution for improving business
performance and wanted to develop a solution for one of the biggest challenges he faced
day to day, Expense Management. Armin and his Qashio Co-founder, Jonathan Lau, saw
the growth of B2B Spend Management solutions like Brex and Ramp in the U.S. and noticed
a gap in the market for a similar product in the MENA region which led to the development of
Qashio.<br/><br/>
Since founding Qashio late last year, the duo have made significant progress and are
continuing to build further on Qashios features and functionalities. The appetite for a
comprehensive and affordable expense management solution in the region has been
significant and Qashio are proud to be able to offer a solution that can make a real impact on
business growth.
    `,
    ],
  },
  {
    name: "Taher Jhanjharya",
    title: "Group Chief Financial Officer",
    company: "EFS Facilities Services",
    image: "Taher Jhanjharya (1).jpg",
    text: [``],
  },

  // {
  //   name: 'Santhosh Narayanan',
  //   title: 'Group Finance Director',
  //   company: 'AlAbbar Group',
  //   image: 'Santosh-Kumar-Narayanan.png',
  //   text: [``],
  // },

  {
    name: "Omar Koleh",
    title: "Chief Financial Officer",
    company: "National Food Products Company",
    image: "Omar Pic.jpg",
    text: [``],
  },

  {
    name: "Adnan Patel",
    title: "Principal, Consulting",
    company: "Crowe",
    image: "Adnan Patel Pic.jpg",
    text: [``],
  },
  {
    name: "Jarna Gaglaani",
    title: "Director Finance",
    company: "Udrive",
    image: "Jarna Pic.jpeg",
    text: [``],
  },
  {
    name: "Dinesh Chandra",
    title: "Regional Vice President - Middle East",
    company: "Automation Anywhere",
    image: "Dinesh.jpg",
    text: [
      `Experienced Sales Leader with over 25 years of experience in developing Enterprise Sales
    Strategies, Business Development, Account Management, Channel &amp; Alliance management,
    Negotiating large and complex deals, Building strategic relationships and Driving strong revenue
    growth.`,
    ],
  },

  {
    name: "Gururajan Krishnamurthy",
    title: "Head - Accountant Channel, Middle East",
    company: "ZOHO",
    image: "Guru photo (1).jpg",
    text: [
      `
    Guru has more than two decades worth of experience in Business Application Software. He
has been instrumental in technology/software adoption amongst organizations across the
Middle East, Africa, and India, with a penchant for SMEs.<br/>
A seasoned navigator and negotiator with Government &amp; Corporate organizations, he
currently heads the accountant channel for the Zoho Finance suite of products in the Middle
East.<br/>
Strategizing Channel Management for Accountants, VAR/SI's, and Product Management of
the Financial suite to address Business and Statutory requirements are some of his key
strengths.
    `,
    ],
  },

  {
    name: "Arun Singh",
    title: "Corporate Treasurer",
    company: "Aramex",
    image: "Arun pic (1).jpg",
    text: [],
  },
];

export const pastspeakers = [
  {
    name: "Ghaith  Ghazi",
    title: "Regional Vice President, Med, Middle East & Africa",
    company: `Tableau `,
    image: "ghaith.jpg",
    companyImage: "unilever.png",
    text: [
      `He is passionate about working with customers, partners, and teams to achieve the Tableau mission of helping people to see and understand data. His efforts are focused on building and enabling agile and productive teams to form a long-term relationship with the Tableau community.

    A crucial part of his role is attracting individuals who fit the Tableau culture. Understanding their strengths, then matching them to their customers, partners and internal teams.
    
    His team’s vision is to become a trusted long-term partner to customers during their Data Transformation journey. According to him, his team is vital to nurture this process.
    `,
    ],
  },
  {
    name: "Adnan Patel",
    title: "Principal, Consulting",
    company: `Crowe `,
    image: "adnan.jpg",
    companyImage: "unilever.png",
    text: [
      `Adnan Patel is a partner with Crowe specializing in assisting growing and mid-sized organizations to leverage integrated, online business applications. He helps clients gain value from SaaS, cloud computing, global CRM/ERP, and on-demand business applications like NetSuite, OpenAir, Salesforce, Sage Intacct, and Adaptive Insights. Adnan has built his expertise through more than 22 years of developing, managing, and enhancing end-to-end business processes. His prior experience as an Oracle senior software engineer and emerging technologies specialist equips him to help clients implement leading integrated, online business applications for CRM, ERP, and omnichannel commerce. `,
    ],
  },
  {
    name: "Sanford Burley",
    title: "Middle East Regional Director",
    company: `Board International `,
    image: "sanford.jpg",
    companyImage: "unilever.png",
    text: [
      `Sanford Burley has spent the last 20+ years in Solution Sales helping customers to achieve value from Analytics and Decision Making (EPM/CPM) solutions across all industries and departments including Finance, HR, Sales, Marketing, Strategy, Operations, Procurement & Supply Chain. As Regional Director of Board Middle East he relishes the opportunity to showcase Board’s unique decision-making tool to help companies achieve their goals and make a difference to their business.
    `,
    ],
  },
  {
    name: "Aalok Kumar",
    title: "Head of Customer Success & Alliances – Middle East & India",
    company: `Board`,
    image: "aalok.jpg",
    companyImage: "unilever.png",
    text: [
      `Aalok is an experienced and highly creative Customer Success Leader with an impressive record of
      achievement in successfully managing customer success strategies and emerging commercial
      relationships. He is responsible for building the Partner Network across the Middle East &amp; India
      working with Senior Partners, Principals, Managing Directors, and practitioners to create value and
      revenue-producing relationships with the largest consultancies, Tax, Audit and Advisory Services
      firms, offshore and boutique firms.
    `,
    ],
  },
  {
    name: "John Strickland",
    title: "Business Development Lead – Middle East & India",
    company: `Board`,
    image: "john.jpg",
    companyImage: "unilever.png",
    text: [
      `John has over 25 years of experience with data. From the early days of the internet building and
      maintaining websites for FTSE 100 companies to building some of the earliest e-commerce sites. He
      built, ran and sold 2 businesses employing 100+ colleagues. In his current role with Board, he
      successfully helps enterprise organisations to analyse, simulate and plan with a unified decision-
      making platform.
    `,
    ],
  },
  {
    name: "Narendran Thillaisthanam",
    title: "Vice President, Emerging Technologies",
    company: `Vuram`,
    image: "narendran.jpg",
    companyImage: "unilever.png",
    text: [
      `Narendran brings is the Vice President of Emerging Technologies at Vuram. He brings more than two decades of industry experience and is based out of Bangalore, India. He has rich experience in the fields of BPM, RPA, and Data Management & Analytics. At Vuram, Narendran and his team of RPA consultants provide solutions using platforms such as Appian RPA, Blue Prism, Automation Anywhere, and UiPath.`,
    ],
  },
  {
    name: "Balaji Prasad Natarajan",
    title: "Group Chief Financial Officer",
    company: `Binladen International Holding Group`,
    image: "balaji.png",
    companyImage: "unilever.png",
    text: [
      `Balaji Prasad – is Group CFO of Binladin International Holding Group, which is
    one of the region’s largest diversified conglomerate consisting of 500+
    companies and is also the parent company of the Construction giant SBG. Prior
    to joining BIHG, he was the Group CFO of Abu Dhabi listed developer Manazel
    PJSC for last 5 years. Previously he has held leadership positions in some of the
    blue chip companies like TYCO, Reliance Industries, Pentair, Sobha Group,
    Suhail Bahwan Group as well as IndianOil.
    
    Out of his career experience of 24 years, he has worked in the region almost 2 decades and brings with
    him extensive Restructuring, Corporate Turnaround, Business Transformation and complex fund raising
    experience across diverse industries such as Contracting, Real Estate, Manufacturing, Trading and Oil &amp;
    Gas.
    He is Fellow Chartered Accountant from India and CPA from US. He holds Bachelor’s degree in Applied
    Sciences from Coimbatore Institute of Technology, India. He is also trained in FX, Hedging &amp; Derivatives
    by Euro Money London.`,
    ],
  },
  {
    name: "Darren Clarke",
    title: "Chief Financial Officer",
    company: `Commercial Bank of Dubai`,
    image: "darren.png",
    companyImage: "unilever.png",
    text: [
      `Darren Clarke is currently the Chief Financial Officer at the Commercial Bank of Dubai. He
    has more than 25 years of banking experience.
    In his previous role, Darren was the CFO for National Australia Bank (NAB) International, and
    was based in Singapore. He worked at NAB for 13 years, including as the CFO for Global
    Markets & Treasury. Prior to that, he worked for JPMorgan Chase in the UK, Luxembourg and
    Hong Kong. Darren holds a Bachelor’s degree in Accounting from the University of
    Wollongong. He is also qualified as a Chartered Accountant with the Institute of Chartered
    Accountants in Australia.
    Joined CBD in April 2018.`,
    ],
  },
  {
    name: "Fawaz Alfawaz",
    title: "Global Chief Financial   Officer",
    company: `TASNEE`,
    image: "fawaz.png",
    companyImage: "unilever.png",
    text: [
      `Mr. Fawaz Al-Fawaz is the Global Chief Financial Officer at TASNEE
    since April 2015 he brings the experience of strategic corporate
    finance activities and people centric approach to the business and is
    focused on building a culture that is performance driven and
    maximizes shareholder value.<br/><br/>
    Presently, he is a Board Member of Tasnee &amp; Sahara Olefins Company;
    Chairman of the Board of Saudi Airlines Cargo Company; Board
    Member of Industrialization and Energy Services Company (TAQA),
    and a Board Member and Chairman of Audit Committee of Saudi
    Chemical.<br/><br/>
    He has over 25 years of experience in Petrochemical Industry at Saudi
    Basic Industries Corporation (SABIC). He held various positions at
    SABIC and prior to joining Tasnee he was the Vice President,
    Corporate Finance, General Manager Shared Services for Finance &amp;
    Accounting, and Assistant Vice President Finance – SABIC Marketing
    LTD. Also, he was Vice Chairman of Board and Audit Committee
    Chairman of Yanbu National Petrochemical Co. (YANSAB); Board
    member of SABIC Affiliates IBN Baytar, Petrokemya, United Jubail,
    SABIC Capital at Amsterdam; Board member of Aluminum Bahrain
    (ALBA) and Board Member and Chairman of Audit Committee of Malath
    <br/><br/>
    Classification: Tasnee - General Information
    <br/><br/>
    Insurance Company.
    He has proven leadership ability and wealth of experience in leadership
    level in the field of Strategic Corporate Finance, Risk Management;
    Auditing; Treasury &amp; Investment, Financial Shared Services, Business
    Transformations as well as in Corporate Functions (Procurement, IT
    ERP systems and Human Resources).<br/><br/>
    Mr. Al-Fawaz earned his bachelor’s degree in Finance &amp; Accounting
    from King Saud University, and attended various international post-
    graduate courses, seminars / conferences on Management Leadership,
    Strategic Finance, International Treasury Management; and Corporate
    Governance from prestigious Institutions.`,
    ],
  },
  {
    name: "Fazeela Gopalani",
    title: `Head Middle East`,
    company: `ACCA, Middle East `,
    image: "fazeela.jpg",
    companyImage: "unilever.png",
    text: [
      `With accounting in her blood thanks to her father who was an ACCA member and ran a successful
    accounting practice in the UK, providing professional financial services to a variety of clients who
    were private individuals, small businesses and large enterprises it’s no surprise Fazeela was
    convinced of the power of the ACCA qualification and truly believes that working for ACCA and
    inspiring others to follow this career path is the utmost tribute to her father.<br/>
    As Head of the Association of Chartered Certified Accountants (ACCA) in the Middle East, Fazeela is
    responsible for leading the operations in 11 countries and representing more than 20,000 students,
    affiliates and members across the region, who work in all sectors and all levels of business across the
    Middle East. One of her fundamental roles is as a thought leader and a conversation starter for all
    things “accounting and finance profession”, in the Middle East, with the purpose of growing the
    understanding of the value that professionally qualified accountants bring to businesses and
    economies across the region.<br/>
    Managing and building strategic relationships with regulators, business leaders, partners and the
    ACCA Middle East stakeholder network to influence debate and conversation around key issues that
    impact the ongoing economic development of the region is a core part of Fazeela’s role. Alongside
    linking up the challenges within the accounting world in the Middle East and shining a light on global
    topics such as sustainability, the audit regulatory environment, digital innovation with the profession
    and elevating the need to promote and advocate women in the finance function.
    ACCA believes that accountancy is a cornerstone profession of society that supports both public and
    private sectors. That’s why we’re committed to the development of a strong global accountancy
    profession and the many benefits that this brings to society and individuals and why Fazeela acts as a
    conversation starter raising awareness around the integral role that accountants play to businesses
    and economies particularly within the Middle East using adoption of VAT, IFRS Changes, public
    sector moving from cash accounting to accrual accounting, embedding Islamic Finance and not
    forgetting to mention business sustainability during this current pandemic as validation, given the
    professions lead on this areas.<br/>
    Fazeela works closely with many educational institutions and government bodies across the region
    to encourage new generations to not only understand the importance of the almost pandemic proof
    profession, but the significant role a professionally qualified accountant plays in building economies,
    thus encouraging the next generation to consider and undertake a career in accountancy and
    finance, whilst raising the importance of the financial literacy agenda being embedded at an early
    age.<br/>
    ACCA understands that in this ever-changing global environment young people require resilience and
    adaptability – skills that are proving to be essential to navigate effectively through whatever
    situations arise and part of her education agenda is to provide insights and tools that enable the next
    generation to possess some of the most important skills that employers will be looking for such as
    creativity, communication and collaboration, alongside empathy and emotional intelligence; and
    <br/>
    being able to work across demographic lines of differences to harness the power of the collective
    through effective teamwork.<br/>
    Fazeela ensures that through her leadership in the Middle East, ACCA is seen as a force for public
    good and remains true to its core values of building a sustainable global profession by re-investing
    surplus to deliver member value by leveraging our respected research, continue to answer today’s
    questions that prepares us for tomorrow, actively encourages continued professional development
    and continues to shape and develop the accounting and finance profession for the next generation.
    Fazeela is an FCCA member and has over 18 years of experience in the field of accounting and
    finance. Fazeela has been the Head of Education for ACCA in the Middle East and she previously was
    a Senior Manager at PwC in Dubai. Prior to moving to the Emirates Fazeela managed and owned an
    accounting practice in the UK providing professional financial services to a variety of clients.
    Fazeela studied her Bachelor’s degree from University of Birmingham and her MBA from University
    of Strathclyde. She lives in Dubai with her husband and two children.`,
    ],
  },
  {
    name: "Harikishan Rankawat",
    title: `Secretary`,
    company: `ICAI`,
    image: "hari.jpg",
    companyImage: "unilever.png",
    text: [
      `Harikishan Rankawat is the Founder and Managing Partner of an auditing firm in the UAE. He
    is a Chartered Accountant (CA) from India and Certified Public Accountant (CPA) from USA. He
    is a Certified SAP R/3 Application Consultant from “SAP AG Germany” and also has the IFRS
    certification from “The Institute of Chartered Accountants of India”.
    He is a Senior Finance professional having 25 years of diversified experience in various roles
    during his career. Currently, he is serving as Secretary of “The Institute of Chartered
    Accountants of India – Dubai Chapter” and as a Trustee of the “Rajasthan and Business
    Professional Group” (RBPG) in Dubai.
    He is based in Dubai for the last 18 years and had an opportunity to work in the senior finance
    role with Al Futtaim Engineering, Al Futtaim Panatech, Dubai Festival City, Al Futtaim Real
    Estate, Philip Morris and Dubai World Trade Centre LLC.
    Prior to Dubai, he has worked for 6 years in Mumbai, India with Century Textiles &amp; Industries
    Limited (B.K. Birla Group) and Viraj Group of companies.`,
    ],
  },

  {
    name: "Irfan Nagi",
    title: "Chief Financial Officer",
    company: `Tanmiah`,
    image: "irfan.jpg",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "Khalid Siddiqui",
    title: "Chief Information  Officer",
    company: `HSBC Saudi Arabia`,
    image: "khalid.jpg",
    companyImage: "unilever.png",
    text: [
      `Khalid is a highly accomplished and experienced technology leader with expert proficiency in developing
    and implementing innovative technical solutions throughout the globe. Outstanding background in
    development and managing multiple technologies, providing architecture development, relationship
    management, and staff leadership. Khalid has strong competency and broad experience in the capital
    markets and is an advocate for cloud, fintech and agile.`,
    ],
  },
  {
    name: "Manish Manchandya",
    title: " Chief Financial   Officer",
    company: `Saudi Electricity Company`,
    image: "Manish.jpg",
    companyImage: "unilever.png",
    text: [
      `Mr. Manish Manchandya is currently the Executive Vice President Finance &amp; Group CFO of the company.
    He was appointed on this positon on 1 Jan 2020. Prior to being appointed as the Group CFO, Mr. Manish
    held various positions in SEC and the last one being the Executive Director Treasury. He joined the
    company in 2008 and since then have moved upwards and held various roles within the Finance team.
    Mr. Manish has an extensive experience in Treasury and Corporate Finance and have been actively
    involved in debt raising activities of the company. He has keen interest in Digital Transformation and the
    use of technology to improve financial systems and he leading the digital transformation of SEC’s finance
    function. Mr. Manish has completed his Executive Program in General Management from MIT Sloan
    School of Business and holds an ACE certificate as well from MIT Sloan.`,
    ],
  },
  {
    name: "Martin  Bradley",
    title: "Chief Financial Officer",
    company: `Dulsco`,
    image: "martin.jpg",
    companyImage: "unilever.png",
    text: [
      `Martin Bradley has over 20 years’ experience in
    Financial strategic planning dating back to 1994
    working across key manufacturing and government
    organizations in the UK ( Scotland) . His international
    experience encompasses prominent roles in risk
    mitigation operating from Singapore, before he moves
    to the UAE in 2009.
    
    In the UAE Martin has led financial development and
    restructuring across flagship local and international
    organizations including Hepworth, MKM Group,
    Bee’ah, and Etihad Rail. He planned and executed
    transformation strategies across finance IT, HR , and
    procurement functions driving the awarding of
    multibillion dollar tenders through strong corporate
    governance and financial innovation.
    
    He joined Dulsco as the CFO in 2019 and has been
    entrusted to take lead in the business transformation
    of the 84 years old Emirati organization.`,
    ],
  },

  {
    name: "Mustafa Malik",
    title: "Head Of Finance/CFO",
    company: `Philips KSA`,
    image: "mustafa.jpg",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "Narayanan  Vaidyanathan",
    title: "Head of Business Insights",
    company: `ACCA`,
    image: "Narayanan.jpg",
    companyImage: "unilever.png",
    text: [
      `Narayanan comments on issues pertinent to emerging technologies, and technology policy. He led on ACCA’s report on blockchain, machine learning (where he also developed training for finance professionals) and digital ethics. He’s presented extensively around the world, written on a range of topics in his areas, contributed to policy such as via all-party parliamentary groups and collaborated with standard setters such as IESBA. He also oversees a team of policy specialists in areas including sustainability, economics, and the public sector.
    `,
    ],
  },

  {
    name: "Neeraj Ritolia",
    title: "Chairman",
    company: `ICAI Abu Dhabi`,
    image: "neeraj.jpg",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "Paul   Simmons",
    title: "SVP Financial Controller, EMEA & Asset Management MEA",
    company: `Marriott`,
    image: "paul.jpg",
    companyImage: "unilever.png",
    text: [
      `Paul Simmons is Marriott International’s SVP Financial Controller, EMEA & MEA
    Asset Management, overseeing the company’s above property financial and
    accounting disciplines for EMEA and heading up the Asset Management function
    specifically for MEA.<br/>
    He joined Marriott International in 2005, after a long career with Whitbread,
    Marriott’s strategic franchise partner in the UK. He started at Whitbread as a
    Senior Analyst in 1989, serving in roles of increasing responsibility before a
    promotion to Financial Controller in 2000 and Group Financial Controller in 2001.
    In these roles, he was responsible for the central finance functions.<br/>
    Before joining Whitbread Paul worked for a short time at Rank Xerox as their
    Financial Accountant, and prior to that, in his first role post full-time education, as
    an Auditor for a small Public Practice firm in Hertfordshire.<br/>
    Paul is married to Susan and has three boys, two dogs and a Siberian cat. Paul’s
    interests outside of work are a little bit of golf when he gets the opportunity, plus
    he likes to focus on his main a hobby, a numismatist, which he has been since
    1978.`,
    ],
  },

  {
    name: "Robert  van der Klauw ",
    title: "Head of Finance",
    company: `Munich Re`,
    image: "robert.jpg",
    companyImage: "unilever.png",
    text: [
      `Robert van der Klauw (The Netherlands, 1968) is regional Head of Finance for Munich Re, one of the world’s leading reinsurers. His responsibility also includes the financial management for Munich Re’s Third Party Administrator (TPA) MedNet, and health insurance technology company Munich Re HealthTech. Robert has 25 years’ experience of serving in leadership roles in the banking and insurance industry with global financial institutions including ING, MetLife and Munich Re across Europe, Asia and the Middle East.
    `,
    ],
  },

  {
    name: "Sebastian Samuel",
    title: `Chief Information Officer`,
    company: `AW Rostamani`,
    image: "sebatian.jpg",
    companyImage: "unilever.png",
    text: [
      `Sebastian T Samuel is the Chief Information Officer at AW Rostamani Group, Dubai.
    With more than 25 Years of experience in the Information Technology industry, Samuel heads a
    team of professionals with responsibilities for technical oversight including research,
    development, technology, and product design activities spanning the AW Rostamani portfolio
    which includes Automotive, Real Estate, Manufacturing, Logistics, Retail, Travel and Tours.
    Sebastian holds Master’s in Computer Applications and he is also certified in Enterprise
    Governance of IT (CGEIT) &amp; Enterprise Architecture (TOGAF). He is is an ExO Consultant and
    Coach who can help organizations to leverage Exponential Technologies for their growth. He has
    expertise in Digital Transformation. Modernizing Technology Architecture, Data Science and
    ML Implementations, Customer Experience Technologies, ERP Modernization, Corporate
    Strategy &amp; Performance Management, Governance Risk and Compliance, and Disaster Recovery
    and Business Continuity Management.<br/>
    Sebastian provides leadership for the continued development of an innovative, robust, and secure
    digital environment throughout the group. His initiatives include improving service delivery
    through embracing effective and efficient use of technology; creating business models for
    delivering new services and leading strategic initiatives to transform the business through
    effective and efficient use of technology to be flexible, adaptable and responsive to customer
    needs.`,
    ],
  },
  {
    name: "Sherif ElZeiny",
    title: "Group  Chief Financial   Officer",
    company: `ELSEWEDEY ELECTRIC`,
    image: "sherif.jpg",
    companyImage: "unilever.png",
    text: [``],
  },

  {
    name: "Sunder Nurani",
    title: `Chairman`,
    company: `ICAI`,
    image: "sundar.jpg",
    companyImage: "unilever.png",
    text: [
      `Sunder Nurani is the Fellow Member of the Institute of Chartered Accountants of India and currently the
    Chairman of the largest overseas and most vibrant chapter of ICAI, Dubai Chapter. He has more than 25
    years of work experience in the field of statutory audits. He is a strong advocate of continuous learning
    and re-skilling. Sunder finds true value in the relationships made at work, which explains his passion for
    building and maintaining professional relationships and giving back to the Chartered Accountancy
    profession. He always believes in creating an impact that matters and is actively involved in various CSR
    activities and sustainability initiatives. He believes and follows the saying that knowledge shared is
    knowledge doubled. He is a good mentor and always look for opportunities to guide students and groom
    future leaders. Sunder Nurani is a strong advocate of diversity and inclusion and enjoys volunteering for
    a good cause.`,
    ],
  },

  {
    name: "Tamer  Kadry",
    title: "Chief Financial Officer",
    company: `Mars Wrigley`,
    image: "tamer.jpg",
    companyImage: "unilever.png",
    text: [
      `Tamer is currently the Vice President & CFO for Global Emerging Markets within the Mars
    Wrigley segment of Mars Inc., and is a member of the Mars Wrigley Global Finance
    Leadership Team. Tamer covers a geography that is home to more than half of the world’s
    consumers and is the future growth engine globally. He is managing the full P&L of a business
    that is > USD$ 4B in RSV, more than 6000 associates and a broad footprint of manufacturing
    facilities.<br/>
    Tamer started his career in Auditing with Deloitte, he then moved to Oil & Gas with one of
    British Gas Downstream assets in Cairo. He joined Mars in 2002 and worked in geographies
    across Europe, CIS, MEA, China, Australia and Asia, based in Milan, Brussels and currently
    Dubai.<br/>
    Throughout his career, Tamer has developed experiences with a broad range of business
    situations (startups, turn arounds and sustaining success) in both the developed and emerging
    markets, and has built expertise in leading and engaging large complex organizations.
    Tamer is an Egyptian citizen. He holds a BSc in Accounting and a CPA certificate from
    University of Illinois. He is married and is a father of 3 children and enjoys running, cross-fit,
    travel and reading across various fields.`,
    ],
  },

  {
    name: "Zahid   Kasmani",
    title: "Chief Financial Officer",
    company: `Mabanee Company`,
    image: "zahid.jpg",
    companyImage: "unilever.png",
    text: [``],
  },

  {
    name: "Manish Mathur",
    title: "Chief Financial Officer",
    company: "Dabur International",
    image: "mathur.jpg",
    companyImage: "unilever.png",
    text: [""],
  },

  {
    name: "Muhammed Ali Baig",
    title: "Managing Director-Finance",
    company: "Be’eah",
    image: "baig.jpg",
    companyImage: "unilever.png",
    text: [""],
  },

  {
    name: "Mohammed Shafeek",
    title: "Leader PDC",
    company: "ICAI Abu Dhabi Chapter",
    image: "shafeek.jpg",
    companyImage: "unilever.png",
    text: [""],
  },

  {
    name: "Lokendra Rawat",
    title: "Strategic Accounts Leader",
    company: "Oracle",
    image: "lokendra.jpg",
    companyImage: "unilever.png",
    text: [""],
  },

  {
    name: "Alok Gupta",
    title: "Chief Executive Officer	",
    company: "Y.K. Almoayyed and Sons (YKA)",
    image: "gupta.jpg",
    companyImage: "unilever.png",
    text: [""],
  },
  {
    name: "ABDULAZIZ ALQATIE",
    title: "VP Investment Operations",
    company: `Saudi Telecom Company Saudi Arabia`,
    image: "abdul.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "BYRON FLORES",
    title: `Global Head of Digital
    Finance`,
    company: `Novartis
    Spain `,
    image: "byron.png",
    companyImage: "unilever.png",
    text: [``],
  },

  {
    name: "RAMKUMAR BALASUBRAMANIAM",
    title: `Head of Finance
    Middle East`,
    company: `Barclays Bank`,
    image: "ramkumar.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "SANJEEV AGARWALA",
    title: `Assistant Group
    Managing Director`,
    company: `Al Habtoor Group`,
    image: "sanjeev.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "STANEY PULLOLICKEL",
    title: `Regional Treasurer
    (Commercial) -Asia
    MENAT & SSA`,
    company: `General Electric`,
    image: "staney.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "KULDEEP PURI",
    title: `Country Finance Director`,
    company: `Hitachi ABB Power Grids`,
    image: "kuldeep.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "NEERAJ TECKCHANDANI ",
    title: `Group Chief Financial
    Officer`,
    company: `Apparel Group `,
    image: "neeraj.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "BASEL OMAR ABU-ALI ",
    title: `Group Chief Financial Officer `,
    company: `Ali & Sons Holding`,
    image: "basel.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "ANISH MEHTA",
    title: `Chairman`,
    company: `ICAI Dubai Chapter`,
    image: "anish.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "ANURAG CHATURVEDI",
    title: `Secretary`,
    company: `ICAI Dubai Chapter`,
    image: "anurag.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "RAJESH GARG",
    title: `Group Chief
    Financial Officer`,
    company: `Landmark Group `,
    image: "rajesh.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "AHMAD AL-SHUBBAR",
    title: `Vice President Group
    Treasury & Strategy`,
    company: `Rawabi Holding Group`,
    image: "ahmad.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "EMMA SHAKESPEARE",
    title: `Regional Director
    MENA`,
    company: `SAP Concur`,
    image: "emma.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "ROB DAVIS",
    title: `Vice President
    Solution Management`,
    company: `MicroStrategy`,
    image: "rob.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "CLIVE WEBB",
    title: `Head of Business
    Management -
    Professional Insights team`,
    company: `ACCA`,
    image: "clive.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "ABDUL RAZZAQ AL-BALUSHI",
    title: `Chief Financial Officer`,
    company: `Ooredoo`,
    image: "balushi.png",
    companyImage: "unilever.png",
    text: [``],
  },
  {
    name: "ABDUL RAZZAQ AL-BALUSHI",
    title: `Chief Financial Officer`,
    company: `Ooredoo`,
    image: "balushi.png",
    companyImage: "unilever.png",
    text: [``],
  },
];

export const newsponsors = [];

export const associations = [
  {
    name: "aaa",
    pageLink: "aaa",
    type: "SUPPORTING ASSOCIATION",
    image: "aaa.jpg",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "acca",
    pageLink: "acca",
    type: "SUPPORTING ASSOCIATION",
    image: "acca.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "ADCL",
    pageLink: "ADCL",
    type: "SUPPORTING ASSOCIATION",
    image: "adcl.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "ICAI",
    pageLink: "ICAI",
    type: "SUPPORTING ASSOCIATION",
    image: "icai.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "IASCA",
    pageLink: "IASCA",
    type: "SUPPORTING ASSOCIATION",
    image: "IASCA.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];

export const sponsors = [
  {
    name: "CROWE",
    pageLink: "crowe",
    type: "BUSINESS TRANSFORMATION PARTNER",
    image: "crowe.png",
    text: [
      `An on-demand software requires an on-demand team, and Crowe can help. View our implementation, support, and optimization services tailored to your industry and requirements. 
 
    Run your business better and grow faster with Crowe + NetSuite. Manual spreadsheet entry, disparate systems, murky data – these are all obstacles to growth. By consolidating all your systems into a single place, NetSuite gives you a clear picture of your finances at all times. As one of the first NetSuite solution providers, Crowe can confidently recommend NetSuite as an ideal cloud-based software for fast-growing businesses. 
    `,
    ],
    link: "http://crowe.com/netsuite",
    size: "80%",
    grid: 4,
  },
  {
    name: "Automation Anywhere",
    pageLink: "aa",
    type: "RPA PARTNER",
    image: "aa.png",
    text: [``],
    link: "https://www.automationanywhere.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "SAP Concur",
    pageLink: "sap",
    type: "NETWORKING SPONSOR",
    image: "sap.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Oracle",
    pageLink: "Oracle",
    type: "CLOUD PARTNER",
    image: "oracle.png",
    text: [
      `The Oracle Cloud offers a complete suite of integrated applications for Sales, Service, Marketing, Human Resources, Finance, Supply Chain and Manufacturing, plus Highly Automated and Secure Generation 2 Infrastructure featuring the Oracle Autonomous Database. For more information about Oracle (NYSE: ORCL), please visit us at oracle.com.`,
    ],
    link: "https://www.oracle.com/ae/erp/solutions/finance-strategy/",
    size: "80%",
    grid: 4,
  },

  {
    name: "Tableau",
    pageLink: "tableau",
    type: "LEAD SPONSOR",
    image: "tableau.png",
    text: [
      `Tableau helps people see and understand data. Tableau’s self-service analytics platform empowers people of any skill level to work with data. From individuals and non-profits to government agencies and the Fortune 500, tens of thousands of customers around the world rely on Tableau’s advanced analytics capabilities to make impactful, data-driven decisions.
    `,
    ],
    link: "https://www.tableau.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Board ",
    pageLink: "Board",
    type: "ASSOCIATE PARTNER",
    image: "board.png",
    text: [
      `Board is the #1 Decision-Making Platform, trusted by more than 3,000 organizations worldwide to drive digital transformation across key processes. By unifying business intelligence, planning, and predictive analytics, Board allows companies to produce a single, accurate, and complete view of business information, gain actionable insights, and achieve full control of performance across the entire enterprise. Board is supported by 25 offices alongside a global reseller network and has been implemented in over 100 countries.
    `,
    ],
    link: "http://www.board.com",
    size: "80%",
    grid: 4,
  },
  {
    name: "Vuram  ",
    pageLink: "Vuram",
    type: "NETWORKING PARTNER",
    image: "vuram.png",
    text: [
      `Vuram is a global hyperautomation services company that specializes in low-code enterprise automation. Since its inception in 2011, Vuram has been maintaining 100% customer success and 100% customer references. Powered by passionate people, Vuram has successfully driven digital transformation for several happy enterprise customers across the globe. Vuram has received several prominent recognitions, including the Inc 5000 fastest-growing private companies in the United States, HFS hot vendor (2020), Rising Star- Product Challenger in Australia by iSG Provider Lens™ 2021 report, among others.

      Vuram has consistently ranked in India’s Best Places to Work certified by Great Place to Work® institute. Vuram’s hyperautomation technology stack encompasses business process management (BPM), robotic process automation (RPA), optical character recognition (OCR), document processing, and analytics.

    `,
    ],
    link: "https://www.vuram.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Blueprism",
    pageLink: "Blueprism",
    type: "OFFICIAL RPA PARTNER",
    image: "blueprism.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Microstrategy",
    pageLink: "Microstrategy",
    type: "EXCLUSIVE BUSINESS INTELLIGENCE SPONSOR",
    image: "Microstrategy.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "SAP Concur",
    pageLink: "sap",
    type: "STRATEGIC PARTNER",
    image: "sap.png",
    text: [
      `RSA, a leader in cybersecurity and risk management solutions, provides organizations with technology to
    address challenges across security, risk management and fraud prevention in the digital era. RSA
    solutions are designed to effectively detect and respond to advanced attacks; manage user access
    control; and reduce operational risk, fraud and cybercrime. RSA protects millions of users around the
    world and helps more than 90 percent of the Fortune 500 companies thrive and continuously adapt to
    transformational change.`,
    ],
    link: "https://www.rsa.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "oracle",
    pageLink: "oracle",
    type: "SILVER SPONSOR",
    image: "oracle.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },

  {
    name: "ICAP",
    pageLink: "ICAP",
    type: "SUPPORTING ASSOCIATION",
    image: "ICAP.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const groupByType = groupBy("type");
export const sponsorsPage = groupByType(newsponsors);

export const news = [
  {
    id: "Reason",
    title: "5 reasons to look forward to the Artelligence Forum",
    image: "11.png",
    link: "5-reasons-to-look-forward-to-the-Artelligence-Forum",
  },
  {
    id: "Step",
    title: "Five Steps To Making the Most of Khaleej Times Artelligence Forum",
    image: "12.png",
    link: "Five-Steps-To-Making-the-Most-of-Khaleej-Times-Artelligence-Forum",
  },
  {
    id: "Alexey",
    title: "#MeetTheAISpeaker: In conversation with Alexey Sidorov",
    image: "10.png",
    link: "MeetTheAISpeaker-In-conversation-with-Alexey-Sidorov",
  },
  {
    id: "Matthias",
    title: "#MeetTheAISpeaker: In conversation with Matthias Schindler",
    image: "9.png",
    link: "MeetTheAISpeaker-In-conversation-with-Matthias-Schindler",
  },
  {
    id: "Hr",
    title: "Feeling unwell and want the day off in Abu Dhabi? Go to HR robot",
    image: "1.png",
    link: "Feeling-unwell-and-want-the-day-off-in-Abu-Dhabi--Go-to-HR-robot",
  },
  {
    id: "Hyperloop",
    title: "Hyperloop systems in UAE, other countries now closer to reality: DP World",
    image: "2.png",
    link: "Hyperloop-systems-in-UAE",
  },
  {
    id: "Advancements",
    title: "The advancements in real world Artificial Intelligence",
    image: "3.png",
    link: "The-advancements-in-real-world-Artificial-Intelligence",
  },
  {
    id: "Bigdata",
    title: "AI and Big Tech should not have control over health",
    image: "4.PNG",
    link: "AI-and-Big-Tech-should-not-have-control-over-health",
  },
  {
    id: "Coop",
    title: "The GCC: A bright future",
    image: "5.PNG",
    link: "The-GCC-A-bright-future",
  },
  {
    id: "AIbots",
    title: "AI bots to boost care at Saudi German Hospital",
    image: "6.PNG",
    link: "AI-bots-to-boost-care-at-Saudi-German-Hospital",
  },
  {
    id: "Transform",
    title: "Digital transformation to drive recovery of GCC retail sector post-Covid-19",
    image: "7.PNG",
    link: "Digital-transformation",
  },
  {
    id: "Cyber",
    title: "Cyber security vital to Dubai's sustained progress: Sheikh Hamdan",
    image: "8.PNG",
    link: "Cyber-security-Sheikh-Hamdan",
  },
];
