import React from "react";
import Navbar from "components/NavbarCorporate.js";
import ProfilePageHeader from "components/Headers/CorporateProfilePageHeader.js";
import Register from "components/Register";
import Footer from "components/Footer.js";
import Sponsor from "components/CorporateSponsor.js";
import { sponsors, sponsorsPage, associations } from "./content";
import Testimonials from "components/Testimonials.js";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="REGISTER FOR CORPORATE TAX UAE DECODED" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="CORPORATE_REGISTER" />
      </div>
      <Testimonials />

      <Sponsor sponsors={sponsors} sponsorsPage={sponsorsPage} associations={associations} />
      <Footer />
    </>
  );
}

export default RegisterPage;
