import React from "react";
import Navbar from "components/NavbarCorporate.js";
import LandingPageHeader from "components/Headers/CorporateLandingHeader";
import Footer from "components/Footer.js";
import Agenda from "components/CorporateAgenda";
import SpeakerCard from "components/CorporateSpeakerCard.js";

import { Button, Col, Container, Row } from "reactstrap";
import Sponsor from "components/CorporateSponsor";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;

  return (
    <>
      <div id="outer-container">
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: "url(" + require("assets/images/BG.png") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: -1,
          }}
        ></div>
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <div
            style={{
              backgroundColor: "#fff",
              backgroundImage: "url(" + require("assets/images/BG2.png") + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="py-5"
            data-parallax={true}
          >
            <Container>
              <Row>
                <Col md={9}>
                  <h1 className="title mb-4 section-title text-left text-g">
                    <b>Overview</b>
                  </h1>
                  <br />
                </Col>
                <Col lg={6}>
                  <h3 className="text-dark text-400">
                    Join us in vibrant Abu Dhabi on August 26, 2023, as we delve into the
                    intricacies of UAE corporate tax at the highly anticipated NAFA Corporate Tax
                    UAE event. Building on the success of our previous event in Dubai, this
                    conference promises to provide unparalleled insights and expert guidance on the
                    game-changing tax landscape that is fueling economic growth and shaping the
                    future of businesses. On January 31, 2022, the tax landscape of the region
                    underwent a significant transformation with the announcement by the UAE Ministry
                    of Finance (MoF) of a new federal corporate tax (CT) system. Effective from
                    financial years commencing on or after June 1, 2023, this system has introduced
                    the lowest corporate income tax rate in the GCC region, with a standard rate of
                    just 9%, excluding Bahrain. The UAE CT regime has been thoughtfully designed to
                    incorporate global best practices while minimizing the compliance burden on
                    businesses. At NAFA Corporate Tax UAE, participants will gain a comprehensive
                    understanding of the impact of introducing corporate tax on business profits and
                    learn how to navigate its requirements with ease. Don't miss out on this
                    momentous development that is reshaping the UAE's business landscape.
                    <br />
                    <br />
                    Stay ahead of the curve and position your organization for a tax-efficient
                    future in the UAE. Join us at NAFA Corporate Tax UAE in Abu Dhabi and be part of
                    this transformative event.
                    <br />
                    <br />
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ minWidth: "200px" }}
                      href="/register-corporate-tax-decoded"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        REGISTER INTEREST TO ATTEND
                      </p>
                    </Button>
                  </h3>
                </Col>
                <Col lg={6}>
                  {/* <img
                src={require("assets/images/textgrid.jpg")}
                width="100%"
                alt="textgrid"
              /> */}

                  <div class="embed-responsive embed-responsive-4by3">
                    {/* <iframe
                  width="972"
                  height="547"
                  src="https://www.youtube.com/embed/RJI4O_y80zM?autoplay=1"
                  title="YouTube video player"
                  frameborder="0"
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> */}

                    <iframe
                      width="972"
                      height="547"
                      title="YouTube video player"
                      src="https://www.youtube.com/embed/yufptpaTVhY?autoplay=1"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <section id="agenda" />
          <Agenda />
          <section id="speakers" />
          <SpeakerCard />
          <Sponsor />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
