import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          background: "#DEE4FD",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="">
                <h1 className="section-title text-center text-dark">
                  <b>HEAR WHY INDUSTRY LEADERS PARTICIPATE</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <img
                src={require("assets/images/icons/quote.png")}
                style={{ maxWidth: "100px" }}
                alt="quote"
              />
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <br />
                    <br />
                    <h1
                      className="section-title text-center text-dark"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <br />
                    <h3 className="text-dark text-800">{c.by}</h3>
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial: `
    Excellent well-organised!
	`,
    by: "RAMKUMAR BALASUBRAMANIAM, Head Of Finance Barclays",
  },
  {
    testimonial: `
    Excellent initiative by Khaleej Times
	`,
    by: "PANKAJ MITTAL, Chief Accountant - Intelligent Food",
  },
];

export default Testimonials;
