import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
  Spinner,
} from "reactstrap";

function RegisterPage() {
  const requestType = "Powerlist Nomination Request";

  const [details, setDetails] = React.useState({
    subject: `DIGIBANK Powerlist Nomination Request`,
    type: requestType,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }
    fetch(
      "https://us-central1-futuresec.cloudfunctions.net/api/emailpowerlist",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    )
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setSuccess("Thank you for your Nomination");
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  return (
    <>
      <div
        className="section section-with-space"
        style={{
          backgroundColor: "#e00e28",
          marginTop: "10vh",
        }}
      >
        <Container>
          <Row>
            <Col className="mx-auto" lg="12">
              <h2 className=" section-title mx-auto text-white text-uppercase text-center">
                Nominate
              </h2>
              <Card
                className=" mx-auto p-5 text-white"
                style={{ backgroundColor: "#0A1332" }}
              >
                <Container>
                  <Form onSubmit={onSubmitForm} className="register-form">
                    <h3 className="mx-auto text-uppercase text-center">
                      Personal Details
                    </h3>
                    <Row className="justify-content-center pb-3">
                      <Col md={2}>
                        <label>Full Name*</label>
                        <Input
                          placeholder="Full Name"
                          type="text"
                          name="name"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>

                      <Col md={2}>
                        <label>Email*</label>
                        <Input
                          placeholder="Email"
                          type="text"
                          name="email"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>

                      <Col md={2}>
                        <label>Phone*</label>
                        <Input
                          placeholder="Phone"
                          type="text"
                          name="phone"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>

                      <Col md={2}>
                        <label>Company*</label>
                        <Input
                          placeholder="Company"
                          type="text"
                          name="company"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>
                      <Col md={2}>
                        <label>Job Title*</label>
                        <Input
                          placeholder="Job Title"
                          type="text"
                          name="jobtitle"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>
                    </Row>

                    <h3 className="mx-auto mt-3 text-uppercase text-center">
                      Nominee Details
                    </h3>
                    <Row className="justify-content-center pb-3">
                      <Col md={2}>
                        <label>Full Name*</label>
                        <Input
                          placeholder="Full Name"
                          type="text"
                          name="nomineeName"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>

                      <Col md={2}>
                        <label>Email*</label>
                        <Input
                          placeholder="Email"
                          type="text"
                          name="nomineeEmail"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>

                      <Col md={2}>
                        <label>Phone*</label>
                        <Input
                          placeholder="Phone"
                          type="text"
                          name="nomineePhone"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>

                      <Col md={2}>
                        <label>Company*</label>
                        <Input
                          placeholder="Company"
                          type="text"
                          name="nomineecompany"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>
                      <Col md={2}>
                        <label>Job Title*</label>
                        <Input
                          placeholder="Job Title"
                          type="text"
                          name="nomineejobtitle"
                          onChange={onChangeDetails}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="pt-3 justify-content-center">
                      <Col md={10} className="mt-2">
                        <label>
                          Please provide a brief about your company & your
                          career?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="career"
                          onChange={onChangeDetails}
                        />
                      </Col>
                      <Col md={10} className="mt-2">
                        <label>
                          What are the strategic initiatives/partnerships that
                          you have initiated for your organization?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="initiatives"
                          onChange={onChangeDetails}
                        />
                      </Col>
                      <Col md={10} className="mt-2">
                        <label>
                          What was your goal during the current global crisis &
                          how was is it achieved?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="goal"
                          onChange={onChangeDetails}
                        />
                      </Col>
                      <Col md={10} className="mt-2">
                        <label>
                          What are the efforts and initiatives that makes you
                          stand out from your contemporaries?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="efforts"
                          onChange={onChangeDetails}
                        />
                      </Col>
                      <Col md={10} className="mt-2">
                        <label>
                          What are the financial transformations initiated by
                          you, has improved competitive position and company
                          image?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="transform"
                          onChange={onChangeDetails}
                        />
                      </Col>
                      <Col md={10} className="mt-2">
                        <label>
                          How have the systems you implemented improved the
                          organization’s health and driven secure
                          decision-making?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="health"
                          onChange={onChangeDetails}
                        />
                      </Col>
                      <Col md={10} className="mt-2">
                        <label>
                          How have the systems you implemented that have
                          delivered quantifying results?
                        </label>
                        <Input
                          placeholder=""
                          type="text"
                          name="results"
                          onChange={onChangeDetails}
                        />
                      </Col>
                    </Row>
                    <Button
                      block
                      className="btn mt-3"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Submit
                      {loading ? (
                        <span>
                          {" "}
                          <Spinner color="warning" size="sm" />
                        </span>
                      ) : (
                        ""
                      )}
                    </Button>
                  </Form>
                </Container>
                {error ? (
                  <Alert
                    color="danger"
                    isOpen={true}
                    fade={true}
                    className="text-center"
                    style={{ marginTop: "1rem" }}
                  >
                    {error}
                  </Alert>
                ) : (
                  ""
                )}
                {success ? (
                  <Alert
                    color="success"
                    isOpen={true}
                    fade={true}
                    className="text-center"
                    style={{ marginTop: "1rem" }}
                  >
                    {success}
                  </Alert>
                ) : (
                  ""
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default RegisterPage;
