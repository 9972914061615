import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";

import Slider from "react-slick";
import { groupBy } from "views/content";
import { DataContext } from "DataContainer";
export const newsponsors = [
  {
    name: "One Stream Software",
    pageLink: "One Stream Software",
    type: "NETWORKING PARTNER",
    image: "OS-LogoTM-Horizontal-FC-RGB (1) (1).png",
    text: [``],
    link: "https://onestreamsoftware.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "MCA Management Consultants",
    pageLink: "MCA",
    type: "SILVER SPONSOR",
    image: "Logo MCA_page-0001 (2).jpg",
    text: [` `],
    link: "https://www.consultmca.in/",
    size: "80%",
    grid: 4,
  },

  {
    name: "Al Tamimi & Company",
    pageLink: "Al Tamimi & Company",
    type: "MASTERCLASS SPONSOR",
    image: "Al Tamimi & Company Logo - Blue.png",
    text: [``],
    link: "https://www.tamimi.com/ae-en/",
    size: "80%",
    grid: 4,
  },
  {
    name: "UHY James",
    pageLink: "UHY",
    type: "NETWORKING PARTNER",
    image: "uhy.png",
    text: [``],
    link: "https://uhy-ae.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Tally",
    pageLink: "Tally",
    type: "TECHNOLOGY PARTNER",
    image: "tally.png",
    text: [``],
    link: "https://tallysolutions.com/#gref",
    size: "80%",
    grid: 4,
  },
  {
    name: "Dubai drums",
    pageLink: "Dubai-drums",
    type: "NETWORKING ACTIVITY PARTNER",
    image: "DD Final High Res Logo-01.jpg",
    text: [``],
    link: "https://www.dubaidrums.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Anderson",
    pageLink: "Anderson",
    type: "NETWORKING PARTNER",
    image: "Andersen_Logo_Png_Color.png",
    text: [``],
    link: "https://andersen.com/",
    size: "80%",
    grid: 4,
  },

  {
    name: "ACCA",
    pageLink: "ACCA",
    type: "SUPPORTED BY",
    image: "acca.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "ICAI",
    pageLink: "ICAI",
    type: "SUPPORTED BY",
    image: "icai.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "ADCL",
    pageLink: "ADCL",
    type: "SUPPORTED BY",
    image: "adcl.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Financial IT",
    pageLink: "financialIT",
    type: "MEDIA PARTNER",
    image: "fit.png",
    text: [``],
    link: "https://financialit.net/",
    size: "80%",
    grid: 3,
  },
  {
    name: "GCC Business News",
    pageLink: "GCC",
    type: "MEDIA PARTNER",
    image: "GCC Business News Logo _ 3240x1294_PNG.png",
    text: [``],
    link: "https://www.gccbusinessnews.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "The Financial World Magazine",
    pageLink: "finance-world",
    type: "MEDIA PARTNER",
    image: "tfw.png",
    text: [``],
    link: "https://thefinanceworld.com/",
    size: "80%",
    grid: 3,
  },
];
function Sponsor({ sponsors, associations }) {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: "p-10",
    rows: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };
  const { sponsors: allSponsors } = useContext(DataContext);
  const rows = groupBy("panel")(allSponsors);

  return (
    <>
      <div
        style={{
          background: "url(" + require(`assets/images/BG-3.png`) + ")",
          backgroundColor: "#000",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container className="mb-5">
          <Row>
            <Col xs={12}>
              <h1 className="text-center text-white section-title my-5">
                <b>2024 SPONSORS AND PARTNERS</b>
              </h1>
            </Col>
          </Row>
          {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"}>
                  <a href={`/sponsors/${s.slug}`}>
                    <h3 className="text-400 text-white text-center mb-3">{s.type}</h3>
                    <Card className="rounded-0">
                      <Container fluid>
                        <Row>
                          <Col lg={12}>
                            <img src={s.image} alt={s.name} width="100%" className="rounded-0" />
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
        <Container className="border-top">
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="text-center text-white section-title my-5">
                <b>PAST SPONSORS & PARTNERS</b>
              </h1>
            </Col>

            <Col lg={12} xs={11} className={"ml-auto mr-auto"}>
              <Slider {...settings}>
                {[...newsponsors, ...sponsors, ...associations].map((sponsor, index) => (
                  // <Col lg={3} key={index}>
                  <a href={`/sponsors/${sponsor.pageLink}`}>
                    <Container fluid>
                      <Row>
                        {/* <Col
                          lg={12}
                          className="text-center text-dark border-bottom mb-2"
                          style={{
                            padding: "1rem 0rem",
                          }}
                        >
                          <h5
                            className="text-dark text-400 text-uppercase"
                            style={{ margin: 0, padding: 0 }}
                            dangerouslySetInnerHTML={{
                              __html: sponsor.type,
                            }}
                          ></h5>
                        </Col> */}
                        <Col lg={12}>
                          <Card style={{ borderRadius: "0" }}>
                            <img
                              src={require(`assets/sponsors/${sponsor.image}`)}
                              alt={sponsor.name}
                              width="100%"
                              style={{ borderRadius: "0" }}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </a>
                  // </Col>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
