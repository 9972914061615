import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import Slider from "react-slick";
import { groupByType } from "views/content";

export const newsponsors = [
  // {
  //   name: "MMJS",
  //   pageLink: "MMJS",
  //   type: "GOLD SPONSOR",
  //   image: "MMJS.png",
  //   text: [` `],
  //   link: "https://www.mmjs.co/",
  //   size: "80%",
  //   grid: 4,
  // },

  {
    name: "Odoo",
    pageLink: "Odoo",
    type: "SILVER SPONSOR",
    image: "odoo_logo.png",
    text: [``],
    link: "https://www.odoo.com/",
    size: "80%",
    grid: 4,
  },
  // {
  //   name: "CROWE",
  //   pageLink: "crowe",
  //   type: "SILVER SPONSOR",
  //   image: "crowe.png",
  //   text: [
  //     `An on-demand software requires an on-demand team, and Crowe can help. View our implementation, support, and optimization services tailored to your industry and requirements.

  //   Run your business better and grow faster with Crowe + NetSuite. Manual spreadsheet entry, disparate systems, murky data – these are all obstacles to growth. By consolidating all your systems into a single place, NetSuite gives you a clear picture of your finances at all times. As one of the first NetSuite solution providers, Crowe can confidently recommend NetSuite as an ideal cloud-based software for fast-growing businesses.
  //   `,
  //   ],
  //   link: "http://crowe.com/netsuite",
  //   size: "80%",
  //   grid: 4,
  // },

  {
    name: "Tally",
    pageLink: "Tally",
    type: "TECHNOLOGY PARTNER",
    image: "tally.png",
    text: [``],
    link: "https://tallysolutions.com/#gref",
    size: "80%",
    grid: 4,
  },
  {
    name: "Evas",
    pageLink: "Evas",
    type: "TECHNOLOGY PARTNER",
    image: "Evas_Logo-01.jpg",
    text: [``],
    link: "https://evasinternational.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "MIC Technology services",
    pageLink: "MIC Technology services, ",
    type: "ASSOCIATE PARTNER",
    image: "mictech.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "MIC Tax Services",
    pageLink: "MIC Tax services, ",
    type: "ASSOCIATE PARTNER",
    image: "mictax.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Premier Brains Global",
    pageLink: "Premier",
    type: "ASSOCIATE PARTNER",
    image: "premier.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];
export const oldsponsors = [
  {
    name: "CROWE",
    pageLink: "crowe",
    type: "TITLE SPONSOR",
    image: "crowe.png",
    text: [
      `An on-demand software requires an on-demand team, and Crowe can help. View our implementation, support, and optimization services tailored to your industry and requirements. 
 
    Run your business better and grow faster with Crowe + NetSuite. Manual spreadsheet entry, disparate systems, murky data – these are all obstacles to growth. By consolidating all your systems into a single place, NetSuite gives you a clear picture of your finances at all times. As one of the first NetSuite solution providers, Crowe can confidently recommend NetSuite as an ideal cloud-based software for fast-growing businesses. 
    `,
    ],
    link: "http://crowe.com/netsuite",
    size: "80%",
    grid: 4,
  },
  {
    name: "Anderson",
    pageLink: "Anderson",
    type: "LEAD SPONSOR",
    image: "Andersen_Logo_Png_Color.png",
    text: [``],
    link: "https://andersen.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "ACCA",
    pageLink: "ACCA",
    type: "KNOWLEDGE PARTNER",
    image: "acca.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Tax Gian",
    pageLink: "Tax Gian",
    type: "KNOWLEDGE PARTNER",
    image: "TG Logo_page-0001.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Uniqus",
    pageLink: "Uniqus",
    type: "STRATEGIC PARTNER",
    image: "Uniqus-Logo-JPEG.77ba3508.jpg",
    text: [``],
    link: "https://uniqus.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "EVAS",
    pageLink: "EVAS",
    type: "STRATEGIC PARTNER",
    image: "Evas_Logo-01.jpg",
    text: [``],
    link: "https://evasinternational.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Fame",
    pageLink: "Fame",
    type: "CONSULTING PARTNER",
    image: "fame.png",
    text: [``],
    link: "https://fame.ae/",
    size: "80%",
    grid: 4,
  },
  {
    name: "SBC",
    pageLink: "SBC",
    type: "SILVER SPONSOR",
    image: "SBC logo png.png",
    text: [``],
    link: "https://steadfastconsultants.in",
    size: "80%",
    grid: 4,
  },

  {
    name: "Taxation Society",
    pageLink: "Taxation Society",
    type: "KNOWLEDGE PARTNER",
    image: "taxation.4876ff41.png",
    text: [``],
    link: "https://taxationsociety.org/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Tally",
    pageLink: "Tally",
    type: "TECHNOLOGY PARTNER",
    image: "tally.png",
    text: [``],
    link: "https://tallysolutions.com/#gref",
    size: "80%",
    grid: 4,
  },
  {
    name: "Intelliwings",
    pageLink: "Intelliwings",
    type: "TECHNOLOGY PARTNER",
    image: "intelliwings.png",
    text: [``],
    link: "https://intellewings.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "BSA",
    pageLink: "BSA",
    type: "CONSULTING PARTNER",
    image: "BSA-Logos-Print-CMYK-08-1.d5f8b245.png",
    text: [``],
    link: "https://bsabh.com/",
    size: "80%",
    grid: 4,
  },

  {
    name: "MCA Management Consultants",
    pageLink: "MCA",
    type: "SUPPORTING SPONSOR",
    image: "Logo MCA_page-0001 (2).jpg",
    text: [` `],
    link: "https://www.consultmca.in/",
    size: "80%",
    grid: 4,
  },

  {
    name: "ICAI",
    pageLink: "ICAI",
    type: "SUPPORTING ASSOCIATION",
    image: "icai.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "ADCL",
    pageLink: "ADCL",
    type: "SUPPORTING ASSOCIATION",
    image: "adcl.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Al Tamimi & CO.",
    pageLink: "altamimi",
    type: "STRATEGIC PARTNER",
    image: "Logo Blue_Al Tamimi & Company Logo - Blue.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
  {
    name: "Dubai drums",
    pageLink: "Dubai-drums",
    type: "ENTERTAINMENT PARTNER",
    image: "DD Final High Res Logo-01.jpg",
    text: [``],
    link: "https://www.dubaidrums.com/",
    size: "80%",
    grid: 4,
  },
];
function Sponsor({ sponsors, associations }) {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: "p-10",
    rows: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };
  const rows = [["SILVER SPONSOR"], ["TECHNOLOGY PARTNER"], ["ASSOCIATE PARTNER"]];

  const sponsorsPage = groupByType(newsponsors);
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center text-g section-title">
                <b>2023 SPONSORS & PARTNERS</b>
              </h1>
            </Col>
          </Row>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsorsPage[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <a href={`/corporatesponsors/${s.pageLink}`}>
                      <Container fluid>
                        <Row>
                          <Col
                            lg={12}
                            className="text-center text-dark border-bottom mb-2"
                            style={{
                              padding: "1rem 0rem",
                            }}
                          >
                            <h3
                              className="text-dark text-400 text-uppercase"
                              style={{ margin: 0, padding: 0 }}
                              dangerouslySetInnerHTML={{
                                __html: s.type,
                              }}
                            ></h3>
                          </Col>
                          <Col lg={12}>
                            <Card style={{ borderRadius: "0" }}>
                              <img
                                src={require(`assets/sponsors/${s.image}`)}
                                alt={s.name}
                                width="100%"
                                style={{ borderRadius: "0" }}
                              />
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
          <Row>
            <Col lg={12}>
              <h1 className="text-center text-g section-title">
                <b>PAST SPONSORS & PARTNERS</b>
              </h1>
            </Col>
          </Row>
          <Col lg={12} xs={11} className={"ml-auto mr-auto"}>
            <Slider {...settings}>
              {oldsponsors.map((sponsor, index) => (
                // <Col lg={3} key={index}>
                <a href={`/sponsors/${sponsor.pageLink}`}>
                  <Container fluid>
                    <Row>
                      {/* <Col
                          lg={12}
                          className="text-center text-dark border-bottom mb-2"
                          style={{
                            padding: "1rem 0rem",
                          }}
                        >
                          <h5
                            className="text-dark text-400 text-uppercase"
                            style={{ margin: 0, padding: 0 }}
                            dangerouslySetInnerHTML={{
                              __html: sponsor.type,
                            }}
                          ></h5>
                        </Col> */}
                      <Col lg={12}>
                        <Card style={{ borderRadius: "0" }}>
                          <img
                            src={require(`assets/sponsors/${sponsor.image}`)}
                            alt={sponsor.name}
                            width="100%"
                            style={{ borderRadius: "0" }}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </a>
                // </Col>
              ))}
            </Slider>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
