import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";


function Agenda() {

  // const [activeTab, setActiveTab] = React.useState("1");
  
  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card
            style={
              index === data.length - 1
                ? { backgroundColor: "#00b833" }
                : { backgroundColor: "#00b833" }
            }
          >
            <CardBody>
              <Container>
                <Row>
                  <Col lg={2} className="align-self-center">
                    <h1 className="text-900 text-white">
                      {d.time} - {d.endtime}
                    </h1>
                  </Col>
                  <Col lg={8} className="align-self-center text-white border-left">
                    <h2 className="text-600">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <h1 className="text-700">Agenda</h1>
                    <h2 className="text-700">(Gulf Standard Time)</h2>
                  </div>
                </div>

                <Container>
                  <Row>
                    <AgendaCard data={content1} />
                    <Col lg={12} className={"text-center"}>
                      <Button
                        href="/register-corporate-tax-decoded"
                        className="btn"
                        color="info"
                        size="md"
                      >
                        Register Now
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "08.30",
    endtime: "09.00",
    title: "Networking and Coffee",
  },
  {
    time: "09.00",
    endtime: "09.10",
    title: "Welcome Address by Khaleej Times",
    description: `
    Speaker<br/>
Ravi Tharoor, Chief Executive Officer, Khaleej Times
    `,
  },
  {
    time: "09.10",
    endtime: "09:40",
    title: "Presentation - Importance and implications of corporate tax implementation in the UAE",
    description: `
    <ul>
    <li>Need for Introduction of Corporate tax</li>
<li>benefits for UAE of having introduced Corporate Tax</li>
<li>Impact on Businesses</li>
</ul><br/>

Speakers<br/>
Rohit Kejriwal, Senior Manager, Corporate Tax, Crowe<br/>
Zayd Maniar, Partner, International Liaison Partner, Crowe
    `,
  },
  {
    time: "09.40",
    endtime: "10.00",
    title: "Presentation - Corporate Tax – An Inevitable Reality",
    description: `
    Speakers<br/>
    Rajiv Thakkar, Audit Manager, Jitendra Consulting Group<br/>
    Chetan Mandani, Auditor and Tax Consultant, Jitendra Consulting Group<br/>
    `,
  },
  {
    time: "10.00",
    endtime: "10.30",
    title: "Panel Discussion - Operational Readiness within the industry",
    description: `
    <ul>
    <li>Discussion on the key aspects and challenges related to operational readiness in the industry.</li>
    <li>Sharing of industry-specific insights, experiences, and best practices</li>
    <li>How should Corporate prepare for this important change in compliance?</li>
    </ul><br/>

    Speakers<br/>
Naveen Sharma, Chairman, Taxation Society<br/>
Rajini Ravi, Global Head of Finance Operations, Inchcape Shipping<br/>
Rajiv Thakkar, Audit Manager, Tax Gian <br/>
Smruti Mishra, Manager, Financial Reporting, Etisalat Services Holding<br/><br/>
Moderator
Nimish Makwana – Honorary Director of IOD – UAE Chapter

    `,
  },
  {
    time: "10.30",
    endtime: "10.50",
    title: "Presentation - Basics of Taxation",
    description: `
    Shiraz Khan, Partner & Head of Tax, Al Tamimi & Company`,
  },
  {
    time: "10.50",
    endtime: "11.10",
    title: "Presentation - Get your business Corporate Tax- Ready",
    description: `
    Prateek Toshiwal, Partner, MIC Technology
    `,
  },
  {
    time: "11.10",
    endtime: "11.40",
    title: "Panel Discussion - Free Zones – Significance and Impact of Corporate Tax",
    description: `
<ul>
    <li>Qualifying Free Zones Person – Designated zones and other Free zones</li>
	<li>Qualifying Income, Non-Qualifying income, and Disqualifying income</li>
	<li>Qualifying Activities and Excluded Activities</li>
  </ul><br/>
Speakers<br/>
	Manoj Maru, Director, Transaction Advisory and Group Reporting, DP World<br/>
	Nirav Shah, Director, Fame Advisory <br/>
	Tyne Hugo, Senior Associate, BSA<br/><br/>
Moderator<br/>
	•	Anurag Chaturvedi, Chief Executive Officer, Andersen UAE
   `,
  },
  {
    time: "11.40",
    endtime: "12.10",
    title: "Presentation - International Tax - Impact and Opportunities",
    description: `Speaker<br/>
    Prateem Sengupta, Associate Director – Direct Tax, Andersen UAE`,
  },
  {
    time: "12.10",
    endtime: "12.30",
    title: "Networking & Pre-Lunch",
    description: `
   
    `,
  },
  {
    time: "12.30",
    endtime: "13.00",
    title: "Panel Discussion - Understanding the Compliance requirements of Corporate TAX",
    description: `
    <ul>
    <li>Regulatory and Compliance Considerations </li>
    <li>Computation mechanism of Corporate Tax & use of Technology</li>
    <li>Penalties for non-compliance and potential legal risks</li>
    </ul><br/>
    Speakers<br/>
	Vijay Mohan, Managing Partner, EVAS International<br/>
	Ashkan Parpinchee, Sales & Marketing Director, Crowe UAE<br/>
	Nandaraja Shetty, Senior Country Manager, Tally<br/><br/>
Moderator<br/>
Venkatesh S, Managing Partner, MCA Consultants

    `,
  },
  {
    time: "13.00",
    endtime: "13.20",
    title: "Presentation - Deduction under UAE Corporate Tax and Thin Capitalization Rules",
    description: `
    Speaker<br/>
Vijay Mohan, Managing Partner, EVAS International
    `,
  },
  {
    time: "13.20",
    endtime: "13.50",
    title: "Pre-Lunch Conversation - Transfer Pricing & Significance for Corporates",
    description: `
    <ul>
    <li>Why compliance with Transfer Pricing is important</li>
    <li>How to optimize the compliance requirements under Transfer pricing</li>
    <li>Can this be used as a Planning tool?</li>
    <li>Recommendations for businesses to be ready with this compliance</li>
    </ul><br/>
    Speaker<br/>
    Harshita Raju, Manager International Tax and Transfer Pricing, Crowe UAE<br/>
    Mithilesh Reddy, Senior Partner, Transfer Pricing, SBC Tax Consulting<br/>
    Harikishan Rankawat, Chairman, ICAI Dubai<br/><br/>
    Moderator<br/>
    Dinesh Jangid, Audit Partner, Uniqus<br/>
    `,
  },
  {
    time: "13.50",
    endtime: "14.20",
    title: "Lunch Break",
    description: `
   
    `,
  },
  {
    time: "13.50",
    endtime: "14.20",
    title: "Panel Discussion - Importance of Upskilling",
    description: `
   <ul>
   <li>Significance of upskilling tax professionals for successful corporate tax implementation</li>
	<li>The evolving tax landscape and the need for continuous learning and development</li>
	<li>Emerging Trends and Technologies</li>
   </ul>
   <br/>
   Speaker<br/>
	Steven Ireland, Head Tax, Creation Business Consultants<br/>
	Nauman Mian, Chief Financia Officer, Bayt.com<br/>
	Olena Swain, Global Head Tax, IFFCO Group<br/><br/>
Moderator<br/>
Fazeela Gopalani, Head ACCA Middle East<br/>
    `,
  },
  {
    time: "14.50",
    endtime: "15.10",
    title:
      "Closing Conversation - Exemptions, deductions, and computation mechanism of Corporate Tax",
    description: `
    Speaker<br/>
    John George, Chairman, ICAI Abu Dhabi	<br/>
    Prateek Toshiwal, Partner, MIC Technology<br/><br/>
    Moderator<br/>
    Rajiv Hira, Managing Partner, RHMC Consultants<br/>
    `,
  },
  // {
  //   time: "15.10",
  //   endtime: "15.30",
  //   title: "Networking Activity by Dubai Drums",
  //   description: `
   
  //   `,
  // },
];

// const content2 = [];

export default Agenda;
