import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
// import Slider from "react-slick";

function TextGrid() {
  let pageHeader = React.createRef();
  // const settings = {
  //   dots: true,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   className: "p-10",
  // };

  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          backgroundImage: "url(" + require("assets/images/BG2.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col md={9}>
              <h1 className=" mb-4 text-400 text-left text-main" style={{ fontSize: "3rem" }}>
                <b>Event Overview</b>
              </h1>
            </Col>
            <Col lg={6}>
              <h3 className="text-white text-400">
                In a fast-evolving business world that is heavily influenced by technological
                breakthroughs, finance and accounting professionals remain as cornerstones for any
                business organization, regardless of its size and field. CFOs, VPs of Finance, and
                Heads of Finance and Accounting remain in charge of the most critical aspects of
                organizational financial activity, and despite the cutting-edge technologies that
                have been introduced in the last decade, they are here to stay.
                <br />
                <br />
                The global accounting services market is forecasted to grow at a compound annual
                growth rate (CAGR) of 5.4%. This growth primarily stems from companies reorganizing
                their operations and rebounding from the effects of COVID-19, which initially
                necessitated restrictive containment measures such as social distancing, remote
                work, and the temporary closure of businesses, leading to operational hurdles. The
                market is projected to reach $735.94 billion in 2025, reflecting a CAGR of 6%.
                <br />
                <br />
              </h3>
            </Col>
            <Col lg={6}>
              {/* <img
                src={require("assets/images/textgrid.jpg")}
                width="100%"
                alt="textgrid"
              /> */}

              <div class="embed-responsive embed-responsive-16by9">
                {/* <iframe
                  width="972"
                  height="547"
                  src="https://www.youtube.com/embed/RJI4O_y80zM?autoplay=1"
                  title="YouTube video player"
                  frameborder="0"
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> */}

                <iframe
                  width="972"
                  height="547"
                  title="YouTube video player"
                  src="http://www.youtube.com/embed/RJI4O_y80zM?autoplay=1"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
            <Col lg={10}>
              <h3 className="text-white text-400 mt-4">
                The New Age Finance & Accounting Summit, taking place on May 29, 2024, is an
                unparalleled gathering at the forefront of financial innovation, strategic insights,
                and transformative discussions. Set against the futuristic backdrop of Dubai, this
                premier event brings together leading industry experts, financial analysts, and
                finance gurus to delve into the future intricacies of the corporate finance and
                accounting world, as we explore the importance of agile accounting, corporate tax
                regulations and compliance essentials, and FinTech in a rapidly evolving world.
                Let’s immerse ourselves in a constructive dialogue on the challenges, the
                opportunities, and the trends that shape up today and tomorrow’s finance landscape.
              </h3>
              <br />
              <Button
                className="px-3 py-2 rounded-0"
                color="primary"
                style={{ minWidth: "200px" }}
                href="/sponsor/download-brochure"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  DOWNLOAD BROCHURE
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
