import React from "react";
import { Container, Row, Col } from "reactstrap";

function SpeakerModal({ speaker }) {
  if (speaker && speaker.image) {
    return (
      <>
        <Container className="py-3">
          <Row className="justify-content-start">
            <Col lg={6} className="text-center">
              <img
                src={speaker.image || require("assets/speakers/placeholder.png")}
                alt="speaker"
                width="100%"
                className="rounded mx-auto mt-0"
                style={{ borderRadius: 0 }}
              />
            </Col>
            <Col lg={6} className="text-left align-self-center">
              <h4 className="text-primary text-600 mt-0" style={{ marginBottom: "0" }}>
                {speaker.name}
              </h4>
              <p className="text-dark text-400 mt-0" style={{ marginBottom: "0" }}>
                {speaker.title}
              </p>

              <p className="text-dark text-600 mt-0" style={{ marginBottom: "20px" }}>
                {speaker.company}
              </p>
              {speaker.companyImage && (
                <img
                  src={speaker.companyImage || require("assets/speakers/placeholder.png")}
                  alt="speaker"
                  width="80%"
                  style={{ maxHeight: "80px", maxWidth: "80px" }}
                  className=" rounded-0"
                />
              )}
            </Col>
          </Row>
          {speaker.bio && (
            <Row>
              <Col className="mt-5" md="12">
                <p
                  className="text-dark text-400"
                  dangerouslySetInnerHTML={{ __html: speaker.bio }}
                />
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  } else return "";
}

export default SpeakerModal;
