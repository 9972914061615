import React from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";

import Testimonials from "components/TestimonialsH.js";
// import KeyTopics from "components/KeyTopics.js";

import Stats2 from "components/Stats2.js";
import Stats from "components/Stats.js";
import SpeakerCard from "components/SpeakerCard.js";
import SpeakerCardNew from "components/SpeakerCardNew.js";

import Sponsor from "components/Sponsor.js";
// import SponsorPage from "components/SponsorPage.js";

import { newSpeakers, sponsors, sponsorsPage, associations } from "./content";
import WhoAttend from "components/WhoAttend";
import WhySponsor from "components/WhySponsor";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <Stats />
          <TextGrid />
          <Stats2 />
          <section id="attend" />
          <WhoAttend />
          <section id="sponsor" />
          <WhySponsor />
          <div className="main">
            <section id="speakers" />
            <SpeakerCardNew speakers={newSpeakers} />
            <SpeakerCard speakers={newSpeakers} />
            <Testimonials />
            <section id="sponsors" />

            <Sponsor sponsors={sponsors} sponsorsPage={sponsorsPage} associations={associations} />
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
