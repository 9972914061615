import React from "react";

import Navbar from "components/NavbarCorporate.js";
import ProfilePageHeader from "components/Headers/CorporateProfilePageHeader.js";
import Sponsor from "components/CorporateSponsor.js";
import Footer from "components/Footer.js";

import { sponsors, sponsorsPage, associations } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                {id === "CORPORATE_BROCHURE" && (
                  <>
                    Thank you for your interest. The event brochure will be sent to the email
                    address provided, please check your inbox. In case you do not receive the
                    brochure, please drop us a line: events@khaleejtimes.com
                  </>
                )}
                {id === "CORPORATE_REGISTER" && (
                  <>
                    Thank you for your registration. Our Delegate Acquisition team will get in touch
                    with you to confirm your registration. For any queries you can contact us on
                    events@khaleejtimes.com
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsors} sponsorsPage={sponsorsPage} associations={associations} />
      <Footer />
    </>
  );
}

export default ThankYou;
