import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";
import Testimonials from "components/Testimonials.js";

import { sponsors, sponsorsPage, associations } from "./content";
import { Container, Row, Col, Button } from "reactstrap";



function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in New Age Finance and Accounting Summit on 29th May
                    2024.The event brochure has been sent to the email address provided, please
                    check your inbox. In case you do not receive the brochure, please drop us a
                    line: events@khaleejtimes.com or 800KT
                  </>
                )}
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest in New Age Finance and Accounting Summit on 29th May
                    2024. Our Delegate Acquisition team will get intouch with you to confirm your
                    registration
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in New Age Finance and Accounting Summit on 29th May
                    2024. A member of our team will be in touch with you for further details about
                    the event.
                  </>
                )}


                {id === "sponsorship-enquiry" && (
                  <>
                    Thank you for your interest in Corporate Tax Decode on 31ᵗʰ July
                    2024. A member of our team will be in touch with you for further details about
                    the event.
                  </>
                )}

                {id === "register" && (
                  <>
                    Thank you for your interest in Corporate Tax Decode on 31ᵗʰ July
                    2024. A member of our team will be in touch with you for further details about
                    the event.
                  </>
                )}

                {id === "download-brochure" && (
                  <div>
                    <h2>Thank you for showing your interest in Corporate Tax Decode on July 31ᵗʰ, 2024.</h2>
                    <Button
                      className="mt-4 px-3 py-2 rounded-0"
                      color="primary"
                      // href="/images/FuturesecAgenda.pdf"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", textTransform: 'none', letterSpacing: '1px' }}>
                        Download Event Agenda
                      </p>
                    </Button>                     
                    <p className="mt-4">In case you are unable to download the event Agenda, please drop us a line: marketing@futuresecsummit.com</p>
                  </div>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Testimonials />
      <Sponsor sponsors={sponsors} sponsorsPage={sponsorsPage} associations={associations} />
      <Footer />
    </>
  );
}

export default ThankYou;
