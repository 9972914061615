import React from 'react';
import Navbar from "components/Navbar.js";
import { Container, Col, Row, Button } from "reactstrap";
import Slider from 'react-slick';
import Footer from "components/Footer.js";
import './CorporateTaxDecode.css';

const CorporateTaxDecode = () => {

  const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });


  const speakers = [
    {
      profile: '/images/speakers/ManishArora.png',
      name: 'Manish Arora',
      post: 'Tax Director',
      company: 'Schenider Electrical'
    },
    {
      profile: '/images/speakers/PRATEEKTOSNIWAL.png',
      name: 'Prateek Tosniwal',
      post: 'Partner',
      company: 'MICS International'
    },
    {
      profile: '/images/speakers/RajBal.png',
      name: 'Mr. Raj Bal',
      post: 'Global Head of Tax, Treasury & Customs',
      company: 'Aramex'
    },
    {
      profile: '/images/speakers/PavithraBalaji.png',
      name: 'Pavithra Balaji',
      post: 'Global Stat and Tax Leader- Gas Power',
      company: 'GE Vernova'
    },
    {
      profile: '/images/speakers/KalaiarasanManoharan.png',
      name: 'Kalaiarasan Manoharan',
      post: 'Group Tax Director',
      company: 'Noon'
    },
    // {
    //   profile: '/images/speakers/dummy.png',
    //   name: 'Aatish Shah',
    //   post: 'Head of Tax- GCC Region ',
    //   company: 'DP World'
    // },
    {
      profile: '/images/speakers/HarshDoshi.png',
      name: 'Harsh Doshi',
      post: 'Group Tax Manager',
      company: 'Dubai Investments'
    },
    {
      profile: '/images/speakers/JoaoCabral.png',
      name: 'Joao Cabral',
      post: 'Group Head of Tax & Treasury',
      company: 'Dutco Group'
    },
    {
      profile: '/images/speakers/PrateekBothra.png',
      name: 'Prateek Bothra',
      post: 'Vice President - Tax',
      company: 'Gulf Islamic Investments'
    },
    {
      profile: '/images/speakers/DeepakGarg.png',
      name: 'Deepak Garg',
      post: 'Tax Manager',
      company: 'International Holding Company'
    },
    {
      profile: '/images/speakers/KinjalSampat.png',
      name: 'Kinjal Sampat',
      post: 'Associate Director',
      company: 'MICS'
    },
    {
      profile: '/images/speakers/MaharAfzal.png',
      name: 'Mahar Afzal',
      post: 'Chief Executive Officer',
      company: 'CRESS COOPER'
    },
    {
      profile: '/images/speakers/JaiPrakash.png',
      name: 'Jai Prakash Agarwal',
      post: 'Vice Chairman',
      company: 'ICAI Dubai'
    },
    {
      profile: '/images/speakers/20240729/Dinesh%20Khator%201.jpg',
      name: 'Dinesh Khator',
      post: 'Director - Tax',
      company: 'Deloitte'
    },
    {
      profile: '/images/speakers/20240729/Rishab.jpeg',
      name: 'Rishab Jain',
      post: 'Group Tax and Compliance Manager',
      company: 'Galadari Brothers'
    },
    {
      profile: '/images/speakers/20240729/Nimish%20Makvana%201.png',
      name: 'Nimish Makvana',
      post: 'Senior Partner',
      company: 'Crowe UAE'
    },
    {
      profile: '/images/speakers/20240729/Aashna%20Mulgaonkar.jpg',
      name: 'Aashna Mulgaonkar',
      post: 'Partner & Practice Lead',
      company: 'Transprice GDT Tax Advisors LLC'
    },
    {
      profile: '/images/speakers/20240729/Aatish%20Shah%20DP%20world.jpg',
      name: 'Aatish Shah',
      post: 'Head of Tax- GCC Region',
      company: 'DP World'
    },
    {
      profile: '/images/speakers/20240729/Krishnan%20Narayan.jpg',
      name: 'Krishnan Narayanan Venkat',
      post: 'Chairman',
      company: 'ICAI Abu Dhabi Chapter'
    },
    {
      profile: '/images/speakers/20240729/Nirav%20Shah%20Photo.jpeg',
      name: 'Nirav Shah',
      post: 'Director',
      company: 'FAME Advisory'
    },
    {
      profile: '/images/speakers/20240729/Basel%20Arafe%202.jpg',
      name: 'Basel Arafe',
      post: 'Partner in UAE Corporate Tax',
      company: 'Deloitte'
    },
  ];

  const sponsers = [
    {
      text: 'Media Partner',
      image: '/images/sponsors/sponsor-1.png',
    },
    {
      text: 'Entertainment Partner',
      image: '/images/sponsors/sponsor-2.png',
    },
    {
      text: 'Technology Partner',
      image: '/images/sponsors/sponsor-5.png',
    },
    {
      text: 'Gold Sponsor',
      image: '/images/sponsors/sponsor-4.png',
    },
    {
      text: 'Gold Sponsor',
      image: '/images/sponsors/sponsor-7.jpg',
    },
    {
      text: 'Silver Sponsor',
      image: '/images/sponsors/sponsor-3.jpeg',
    },
    {
      text: 'Silver Sponsor',
      image: '/images/sponsors/sponsor-6.jpg',
    },
  ];

  const supportings = [
    `/images/supporting/supporting-1.png`,
    `/images/supporting/supporting-2.jpg`,
    `/images/supporting/supporting-3.png`,
  ];

  const sponsorSettings = {
    classNames: '',
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    spaceBetween: 30,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          adaptiveHeight: true,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
        }
      }
    ]
  };

  const supportingSettings = {
    classNames: '',
    dots: false,
    infinite: false,
    speed: 500,
    spaceBetween: 30,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const agendaData = [
    {
      time: '8:00 - 9:10',
      details: '<b>Registration and Welcome Coffee</b>',
    },
    {
      time: '9:10 - 9:15',
      details: '<b>Welcome Address:</b> Ravi Tharoor, CEO, Khaleej Times',
    },
    {
      time: '9:15 - 9:35',
      details: '<b>Presentation: </b>UAE Corporate Tax-Actions and Priorities Dinesh Khator, Director – Tax, Deloitte ',
    },
    {
      time: '9:40 - 10:15',
      details: `<b>Panel Discussion: Implementing Effective Tax Strategies for SMEs </b>
                <ul>
                  <li>Practical guidance for small and medium-sized enterprises (SMEs). </li>
                  <li>Tax-saving opportunities and incentives available to SMEs. </li>
                  <li>Best practices for maintaining compliance and avoiding penalties. </li>
                </ul>
                <b>Panelist:</b>
                <ul>
                  <li>Manish Arora, Tax Director, Schneider Electric </li>
                  <li>Raj Bal, Global Head of Tax, Treasury & Customs, Aramex </li>
                  <li>Rishab Jain, Group Tax and Compliance Manager, Galadari Brothers </li>
                  <li>Prateek Bothra, Vice President - Tax, Gulf Islamic Investments </li>
                </ul>
                <b>Moderator: Nimish Makvana, Senior Partner, Crowe UAE </b>
      `,
    },
    {
      time: '10:20-10:40 ',
      details: `
                  <b>Presentation: Principles - Methods and more </b>
                  <ul>
                    <li>UAE Transfer pricing in a nutshell </li>
                    <li>Application of Methods </li>
                    <li>Ideal Transfer Pricing journey </li>
                  </ul>
                  <p>Aashna Mulgaonkar, Partner & Practice Lead, Transprice GDT Tax Advisors LLC  </p>
      `,
    },
    {
      time: '10:45-11:30 ',
      details: `
                <b>Panel Discussion: Navigating Corporate Taxation for UAE Free Zone Persons</b>
                <ul>
                    <li>Real-world examples of successful tax planning in Free Zones.</li>
                    <li>Overview of the current tax regime in UAE Free Zones. </li>
                    <li>Key differences between onshore and free zone tax policies.</li> 
                    <li>The strategic role of Free Zones in the UAE’s economic development.</li>   
                </ul>
                <b>Opening Address: </b>Prateek Tosniwal, Partner, MICS International (10 Minutes Talk)
                <br />
                <b>Panelist: </b>
                <ul>
                  <li>Aatish Shah, Head of Tax- GCC Region, DP World </li>
                  <li>Deepak Garg, Tax Manager, International Holding Company </li>
                  <li>Jai Prakash Agarwal, Vice Chairman, ICAI Dubai </li>
                  <li>Mahar Afzal, Chief Executive Officer, Kress Cooper </li>
                </ul>
                <b>Moderator: </b>Kinjal Sampat, Associate Director, MICS
      `,
    },
    {
      time: '11:30-11:45 ',
      details: 'Coffee Break and Networking  ',
    },
    {
      time: '11:50-12:10',
      details: 'Dubai Drums ',
    },
    {
      time: '12:15-12:50 ',
      details: `
                <b>Panel Discussion: The Role of Technology in Modern Tax Compliance</b>
                <ul>
                  <li>How technology is transforming tax compliance and reporting. </li>
                  <li>Digital tools and platforms for efficient tax management. </li>
                  <li>Case examples of successful technology adoption in tax functions.</li>
                  <li>Leveraging data analytics for improved tax efficiency.</li>
                </ul>
                <br />
                <b>Panelist: </b>
                <ul>
                  <li>Pavithra Balaji, Global Stat and Tax Leader- Gas Power, GE Vernova
                  <li>Joao Cabral, Group Head of Tax & Treasury, Dutco Group </li>
                  <li>Kalaiarasan Manoharan, Group Tax Director, Noon</li>
                  <li>Harsh Doshi, Group Tax Manager, Dubai Investments</li>
                </ul>
                <b>Moderator: </b>CA Krishnan Narayanan Venkat, Chairman, ICAI Abu Dhabi Chapter 
      `,
    },
    {
      time: '12:55-13:15',
      details: `<b>Presentation:</b> Navigating tax accounting in the first year of corporate tax
                <br />Basel Arafe, Partner in UAE Corporate Tax, Deloitte `,
    },
    {
      time: '13:15-13:40',
      details: 'Open Mic Session & Closing Remarks<br />Nirav Shah, Director, FAME Advisory ',
    },
    {
      time: '13:40 -14:30 ',
      details: 'Lunch and Networking  ',
    }
  ];

  return (
    <>    
      <section id="outer-container">
        {/* Navbar */}
        <Navbar home={true} />

        {/* Home */}
        <section
          className="section"
          style={{
            background: "url(" + require(`assets/images/BG.png`) + ")",
            backgroundColor: "#000",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          data-parallax={true}
        >
          {/* <Anime /> */}
          <Container className="py-4">
            <Row className="align-items-center justify-content-center">
              <Col md={12} xs={12} className="text-center">
                <img
                  src='/images/CT-Decoded-logo-1.png'
                  style={{ maxWidth: "400px" }}
                  width="100%"
                  alt=""
                />

                <>
                  <h1
                    className="text-700 mt-3"
                    style={{ fontSize: size ? "1.5rem" : "1rem", color: "#000" }}
                  >
                    31ˢᵗ July 2024
                    <br />
                    DUBAI, UAE
                  </h1>
                </>
                
              </Col>

              <Col md={7} xs={12} className="mt-4" style={{ zIndex: 1000 }}>
                <Container fluid>
                  <Row className="justify-content-center">
                    <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                      <Button
                        className="px-3 py-2 rounded-0"
                        color="primary"
                        style={{ width: "99%" }}
                        href="/sponsor/sponsorship-enquiry"
                      >
                        <p className="m-0 text-700" style={{ color: "#fff" }}>
                          BECOME A SPONSOR
                        </p>
                      </Button>{" "}
                    </Col>
                    <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                      <Button
                        className="px-3 py-2 rounded-0"
                        color="primary"
                        style={{ width: "99%" }}
                        href="/register"
                      >
                        <p className="m-0 text-700" style={{ color: "#fff" }}>
                          REGISTER{size ? " " : <br />}INTEREST
                        </p>
                      </Button>{" "}
                    </Col>
                    {/* <Col lg={3} xs={12} className="text-center">
                      <iframe
                        title="linkedin"
                        style={{ border: "none" }}
                        src="https://www.linkedin.com/pages-extensions/FollowCompany?id=31343765&counter=bottom"
                      ></iframe>
                    </Col> */}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Speakers */}
        <section className='border bg-success pb-5'>
          <h1 className='text-center my-5' style={{ fontSize: '35px', textShadow: '2px 2px 3px green' }}>2024 Speakers</h1>

          <div className='container' style={{ width: '90%' }}>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
              {
                speakers.map((speaker, index) => (
                  <div className='text-center mb-5 col' key={index}>
                    <img src={speaker.profile} className='rounded-circle mb-3 shadow-sm' style={{ height: '200px', width: '200px', objectFit: 'cover', objectPosition: 'top' }} alt="profile" />

                    <h2>{speaker.name}</h2>
                    <p>{speaker.post}</p>
                    <p>{speaker.company}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </section>

        {/* Agenda */}
        <section className='border pb-5'>
          <h1 className='text-center my-5' style={{ fontSize: '35px', textShadow: '2px 2px 3px green' }}>Agenda</h1>

          <div className="container CarpTaxDecAgenda" style={{ width: '90%' }}>
            <table className='table table-hover table-bordered'>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {agendaData.map((s) => (
                  <tr key={s.time}>
                    <td>{s.time}</td>
                    <td><div className="" dangerouslySetInnerHTML={{ __html: s.details }}></div></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        {/* Sponsers */}
        <section className='border bg-dark pb-5'>
          <h1 className='text-center text-success my-5' style={{ fontSize: '35px', textShadow: '2px 2px 3px black' }}>2024 Sponsors</h1>

          <div className="container" style={{ width: '90%' }}>
            <Slider {...sponsorSettings}>
              {sponsers.map((sponser, index) => (
                <div className='px-2' key={index}>
                  <p className='text-center text-white mb-2' style={{ fontSize: '17px' }}>{sponser.text}</p>

                  <img src={sponser.image} className='bg-white' style={{ height: '200px', width: '100%', objectFit: 'contain' }} alt='sponser' />
                </div>
              ))}
            </Slider>
          </div>
        </section>

        {/* Supporting Associations */}
        <section className='border pb-5'>
          <h1 className='text-center my-5' style={{ fontSize: '35px', textShadow: '2px 2px 3px green' }}>Supporting Associations</h1>

          <div className="container" style={{ width: '90%' }}>
            <Slider {...supportingSettings}>
              {supportings.map((supporting, index) => (
                <div className='px-2' key={index}>
                  <img src={supporting} className='bg-white' style={{ height: '200px', width: '100%', objectFit: 'contain' }} alt='sponser' />
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <Footer />
      </section>
    </>
  )
}

export default CorporateTaxDecode
