import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import About from "components/About";
import Organizer from "components/Organizer";
import ContactUs from "components/ContactUs";

import Footer from "components/Footer.js";
import { Redirect } from "react-router";
import Testimonials from "components/Testimonials.js";

function Overview({ match }) {
  const { type } = match.params;
  console.log(type);
  if (!type || !["show-information", "the-organiser", "contact-us"].includes(type)) {
    return <Redirect to="/overview/show-information" />;
  }
  let title = "CONTACT US";
  if (type === "show-information") {
    title = "ABOUT THE FORUM";
  }
  if (type === "the-organiser") {
    title = "ABOUT KHALEEJ TIMES";
  }
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        {type === "show-information" && <About />}
        {type === "the-organiser" && <Organizer />}
        {type === "contact-us" && <ContactUs />}
      </div>
      <Testimonials />
      <Footer />
    </>
  );
}

export default Overview;
