// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ content }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="section"
        style={{
          background: "transparent",
          paddingTop: "7rem",
        }}
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={12} xs={12} className="text-center">
              <img
                src={require(`assets/images/logo/NAFAct.png`)}
                style={{ maxWidth: "500px" }}
                width="100%"
                alt=""
              />
              <hr />

              <h1
                className="text-700 text-main m-0"
                style={{ fontSize: size ? "2.25rem" : "1rem" }}
              >
                26th AUGUST 2023  | ABU DHABI, UAE
              </h1>
            </Col>

            <Col md={7} xs={12} className="mt-4" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/register-corporate-tax-decoded"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        REGISTER{size ? " " : <br />}INTEREST
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/brochure-corporate-tax-decoded"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        DOWNLOAD{size ? " " : <br />}BROCHURE
                      </p>
                    </Button>{" "}
                  </Col>

                  <Col lg={3} xs={3}>
                    <iframe
                      title="linkedin"
                      style={{ border: "none" }}
                      src="https://www.linkedin.com/pages-extensions/FollowCompany?id=31343765&counter=bottom"
                    ></iframe>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;


