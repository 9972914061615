import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

const content2 = [
  { title: "The evolving role of the CFO", image: "11.png" },
  { title: "The Pivotal Role of Women CFOs", image: "4.png" },
  { title: "Finance Leadership", image: "3.png" },
  { title: "Agile Accounting", image: "10.png" },
  { title: "Financial Resilience", image: "9.png" },
  { title: "Navigating Corporate Tax Challenges", image: "8.png" },
  { title: "The Transformation of Financial Decision-Making", image: "6.png" },
  { title: "Strategic Finance", image: "2.png" },
  { title: "Talent Retention and Upskilling Strategies", image: "1.png" },
  { title: "The Role of Finance in ESG (Environmental, Social, Governance)", image: "7.png" },
  {
    title:
      "Technology Adoption and Tech-related Breakthroughs in the Corporate Finance and Accounting Landscape",
    image: "5.png",
  },
];
const content = [
  {
    title:
      "To showcase the latest trends and technological advancements in the finance and accounting industry.",
    image: "27.png",
  },
  {
    title:
      "To provide a platform for CFOs, stakeholders, including finance professionals, accounting industry leaders, regulators, and financial technology experts.",
    image: "32.png",
  },
  {
    title:
      "To discuss challenges and breakthroughs in the corporate finance and accounting fields, with a focus on the leading role of CFOs and the integration of technology.",
    image: "32.png",
  },
  {
    title:
      "To facilitate B2B networking and deal closing opportunities for sponsors and add value to attending delegates.",
    image: "32.png",
  },
];
const size =
  document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;

function KeyNotes() {
  return (
    <>
      <div
        style={{
          // background: "url(" + require(`assets/images/revenue-operations-concept.jpg`) + ")",
          background: "#000",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className=" mb-4 text-400 text-center text-main" style={{ fontSize: "3rem" }}>
                <b>Event Key Highlights</b>
              </h1>
              <br />
            </Col>
          </Row>
          <Row className="text-700 justify-content-center" style={{ marginTop: "1rem" }}>
            {content2.map((c, i) => (
              <Col lg={4} xs={11} className="px-1">
                <Card
                  style={{
                    background: "rgba(0,0,0,0.1)",
                    height: size ? "100px" : "none",
                  }}
                  className="px-3"
                >
                  <div className="d-flex">
                    <div
                      className="px-2 align-self-center rounded-circle mr-2"
                      style={{
                        minWidth: 100,
                        height: 100,
                        background: i % 2 === 0 ? "#6ac2be" : "#00a4c3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "5px solid #ffffff",
                      }}
                    >
                      <img
                        src={require(`assets/images/newicons/${c.image}`)}
                        style={{ width: "60px", height: "60px" }}
                        alt="about"
                        className=""
                      />
                    </div>
                    <div
                      className="d-flex align-items-center w-100"
                      style={{
                        background: "linear-gradient(270deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 80%)",
                        // // borderRadius: "30px",
                      }}
                    >
                      <h3 className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</h3>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center mt-5">
            <Col className="ml-auto mr-auto mt-5" md={12}>
              <h1 className=" mb-4 text-400 text-center text-main" style={{ fontSize: "3rem" }}>
                <b>Event Objectives</b>
              </h1>
              <br />
            </Col>
          </Row>
          <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
            <Row className="text-700 justify-content-center" style={{ marginTop: "1rem" }}>
              {content.map((s, index) => (
                <Col
                  key={index}
                  lg={3}
                  xs={6}
                  className=" p-4"
                  style={{ border: "1px solid #fff" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: 70,
                      height: 100,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        background:
                          "linear-gradient(270deg, rgba(28,151,75,1) 0%, rgba(26,130,172,1) 97%)",
                        width: 50,
                        height: 50,
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: -100,
                      }}
                    ></div>
                    <h1 className="text-700 text-white" style={{ fontSize: "4rem" }}>
                      {index + 1}
                    </h1>
                  </div>

                  <h3 className="text-white text-700 mt-2">{s.title}</h3>
                </Col>
              ))}
            </Row>
          </ScrollAnimation>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
